import { Country } from '../../types/Country'

export const getFilteredByCurrencyCountries = (availableCountries: Country[], orderCurrency: string) =>
  availableCountries
    .filter((c) => c.defaultCurrency === orderCurrency)
    .map((c) => ({
      value: c.countryCode,
      label: c.countryName
    }))

export const getDefaultCountryValue = (filteredByCurrencyCountries: { value: string; label: string }[]) =>
  filteredByCurrencyCountries.length === 1 ? filteredByCurrencyCountries[0].value : undefined
