import theme from '../../assets/theme'
import Box from '@mui/material/Box'
import styled from '@emotion/styled'

const StyledBox = styled(Box)`
  padding: ${theme.spacing(8)};
  ${() => theme.breakpoints.down('mobile')} {
    padding: ${theme.spacing(8, 5)};
  }
`

export default StyledBox
