import React from 'react'
import theme from '../../assets/theme'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { StyledErrorPanel } from '../../components/layout/Container'

type SystemErrorProps = {
  title: string
  children?: React.ReactNode
}

const SystemErrors = (props: SystemErrorProps) => {
  return (
    <StyledErrorPanel>
      <Box>
        <Typography variant="errorHeading">{props.title}</Typography>
      </Box>
      <Box
        sx={{
          pt: theme.spacing(7)
        }}
      >
        {props.children}
      </Box>
    </StyledErrorPanel>
  )
}

export default SystemErrors
