import { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosRequestHeaders } from 'axios'
import { trackAxiosError } from '../utils/metrics/appInsights/AppInsights'
import { GetPrePopulationId, prePopulationIdHeaderName } from '../utils/userInfoPopulation'

const onRequestError = (error: AxiosError): Promise<AxiosError> => {
  trackAxiosError(error)
  return Promise.reject(error)
}

const onResponseError = (error: AxiosError): Promise<AxiosError> => {
  trackAxiosError(error)
  return Promise.reject(error)
}

const onRequestFulfilled = (config: AxiosRequestConfig) => {
  const prePopulationId = GetPrePopulationId()

  const headers: AxiosRequestHeaders = config.headers || {}
  if (prePopulationId !== undefined) {
    headers[prePopulationIdHeaderName] = prePopulationId
  }

  return config
}

export function setupInterceptorsTo(axiosInstance: AxiosInstance): AxiosInstance {
  axiosInstance.interceptors.request.use(onRequestFulfilled, onRequestError)
  axiosInstance.interceptors.response.use(undefined, onResponseError)
  return axiosInstance
}
