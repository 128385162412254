import { AxiosError } from 'axios'
import { useParams } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import { retry } from '../api/apiBaseUrl'
import { apiClient } from '../api/apiClient'
import { RedirectUrls } from '../types/RedirectUrls'

const fetchRedirectUrlsKey = 'fetch-redirect-urls'

const fetchRedirectUrls = async (shortCode: string): Promise<RedirectUrls> => {
  const url = `/paymentjourney/redirectUrl/${shortCode}`
  const response = await apiClient.get<RedirectUrls>(url)
  return response.data
}

export const useRedirectPolling = ({
  onSuccess,
  onError,
  timeout = 30
}: {
  onSuccess: (data: RedirectUrls) => void
  onError: (error?: AxiosError) => void
  timeout?: number
}) => {
  const { shortCode } = useParams()
  const [start, setStart] = useState(true)
  const [timer, setTimer] = useState<NodeJS.Timeout | null>(null)

  const onErrorHandler = (error: AxiosError) => {
    setStart(false)
    onError(error)
  }
  useEffect(() => {
    if (start) {
      const _timer = setTimeout(() => {
        setStart(false)
        onError()
      }, timeout * 1000)

      setTimer(_timer)
    }
  }, [start])

  useQuery<RedirectUrls, AxiosError>([fetchRedirectUrlsKey, shortCode], () => fetchRedirectUrls(shortCode as string), {
    onSuccess: (data: RedirectUrls) => {
      if (data.redirectUrl == null) {
        return
      }

      setStart(false)

      if (timer) {
        clearTimeout(timer)
      }

      onSuccess(data)
    },
    onError: (error: AxiosError) => {
      onErrorHandler(error)
    },
    enabled: start,
    refetchInterval: start ? 3000 : false,
    refetchOnWindowFocus: false,
    retry
  })

  return { rerun: () => setStart(true) }
}
