import React from 'react'
import SpendingLimitTable from '../payment/SpendingLimitTable'
import OrderErrors, { ErrorMessage } from './OrderErrors'
import useFetchCacheData from '../../custom-hooks/useFetchCacheData'
import { useNavigate } from 'react-router-dom'

const InsufficientFunds = () => {
  const paymentJourneyData = useFetchCacheData()
  const navigate = useNavigate()

  if (paymentJourneyData === undefined) {
    navigate('/error', {
      replace: true
    })

    return null
  }

  return (
    <OrderErrors
      marketplaceName={paymentJourneyData.marketplace.name}
      returnToMarketPlaceUrl={paymentJourneyData.paymentJourney.paymentDeclinedRedirectUrl}
    >
      <ErrorMessage>
        Unfortunately, your current spending limit with Kriya Payments is insufficient. Please return to&nbsp;
        {paymentJourneyData.marketplace.name} and choose another payment option to complete your order.
      </ErrorMessage>
      {paymentJourneyData && paymentJourneyData.buyerRiskDecision.availableLimit ? (
        <SpendingLimitTable
          availableLimit={paymentJourneyData.buyerRiskDecision.availableLimit}
          paymentDetails={paymentJourneyData.paymentDetails.payment}
        />
      ) : null}
    </OrderErrors>
  )
}

export default InsufficientFunds
