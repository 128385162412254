import React from 'react'
import { UseFormRegister, FieldValues, FieldError } from 'react-hook-form'
import theme from '../../assets/theme'
import ErrorText from './FormErrors'
import TextField from '@mui/material/TextField'
import FormControl from '@mui/material/FormControl'
import FormLabel from '@mui/material/FormLabel'
import Typography from '@mui/material/Typography'
import { SxProps } from '@mui/material/styles'

interface Props {
  register: UseFormRegister<FieldValues>
  name: string
  label: string
  optional?: boolean
  validation?: { [key: string]: string | boolean | {} }
  error?: FieldError
  placeholder?: string
  showErrorIcon?: boolean
  disabled?: boolean
  style?: SxProps<object>
}

const TextInput: React.FC<Props> = ({
  register,
  name,
  label,
  validation = {},
  error,
  placeholder = '',
  optional,
  showErrorIcon,
  disabled = false,
  style = {}
}) => {
  return (
    <FormControl sx={style}>
      <FormLabel htmlFor={name}>
        {optional ? (
          <>
            {label}{' '}
            <Typography variant="inputLabel" sx={{ color: theme.palette.common.textGrey600 }}>
              (optional)
            </Typography>{' '}
          </>
        ) : (
          label
        )}
      </FormLabel>

      <TextField
        {...register(name, validation)}
        id={name}
        data-cy={name}
        placeholder={placeholder}
        error={error ? true : false}
        aria-invalid={error ? 'true' : 'false'}
        disabled={disabled}
        aria-describedby={error && `${name}-error`}
      />
      {error && (
        <ErrorText id={name} showIcon={showErrorIcon}>
          {error.message}
        </ErrorText>
      )}
    </FormControl>
  )
}

export default TextInput
