import React, { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import TextInput from '../../components/input/TextInput'
import { emailPattern } from '../../components/input/emailRegExp'
import { OrderDetails } from '../../types/OrderDetails'
import { useBuyerVerification } from '../../custom-hooks/useBuyerVerification'
import { useUpdatePaymentJourneyInfo } from '../../custom-hooks/useUpdatePaymentJourney'
import useFetchCacheData from '../../custom-hooks/useFetchCacheData'
import { BusinessType } from '../../types/enums/BusinessType'
import { defaultCountryCode } from '../../consts'
import FormControl from '@mui/material/FormControl'
import FormLabel from '@mui/material/FormLabel'
import Typography from '@mui/material/Typography'
import PannelWithDefaultButtons from '../../components/common/button/PannelWithDefaultButtons'
import { PaymentJourneyInfo } from '../../types/PaymentJourneyInfo'
import CreditCheck from '../../components/creditCheck/CreditCheck'
import SoleTraderIdentifierSelection from './SoleTraderIdentifierSelection'
import { SoleTraderIdentifier } from '../../types/enums/SoleTraderIdentifier'
import PhoneInput from '../../components/input/PhoneInput/PhoneInput'
import { Country } from '../../types/Country'
import theme from '../../assets/theme'
import Box from '@mui/material/Box'

type SoleTraderDetailsForm = {
  email?: string
  phoneNumber?: string
}

export type SoleTraderDetailsProps = {
  orderInfo: OrderDetails
  onSaveuserMutationSuccess: (data: PaymentJourneyInfo) => void
  availableCountries?: Country[]
}

const SoleTraderDetails = ({ orderInfo, onSaveuserMutationSuccess, availableCountries }: SoleTraderDetailsProps) => {
  const { shortCode } = useParams()
  const navigate = useNavigate()
  const [loading, setLoading] = useState<boolean>(false)
  const paymentJourneyData = useFetchCacheData()

  const form = useForm<SoleTraderDetailsForm>({
    mode: 'onChange',
    defaultValues: {
      email: paymentJourneyData?.orderDetails?.company?.companyIdentifier
    }
  })

  const {
    register,
    handleSubmit,
    formState: { errors },
    control
  } = form

  const getCompanyIdentifier = (soleTraderIdentifier: SoleTraderIdentifier, values: SoleTraderDetailsForm) => {
    return soleTraderIdentifier === SoleTraderIdentifier.Email ? values.email : values.phoneNumber
  }

  const buyerVerificationMutation = useBuyerVerification({
    onSuccess: (response) => {
      if (response.status === 204) {
        navigate(`/${shortCode as string}/unverified-sole-trader`)
      }

      if (response.status === 200) {
        if (response.onboardingRedirectUrl) {
          window.location.replace(response.onboardingRedirectUrl)
        } else {
          saveUserMutation.mutate({
            orderDetails: {
              ...orderInfo,
              company:
                orderInfo.company != null
                  ? orderInfo.company
                  : {
                      companyIdentifier: getCompanyIdentifier(
                        soleTraderIdentifier,
                        form.getValues() as SoleTraderDetailsForm
                      ),
                      type: BusinessType.SoleTrader,
                      countryOfRegistration: defaultCountryCode
                    }
            },
            journey: {}
          })
        }
      }
    }
  })

  const saveUserMutation = useUpdatePaymentJourneyInfo({
    onSuccess: onSaveuserMutationSuccess
  })

  const onSubmit = (formValues: SoleTraderDetailsForm) => {
    setLoading(true)
    buyerVerificationMutation.mutate(getCompanyIdentifier(soleTraderIdentifier, formValues)!)
  }

  const [soleTraderIdentifier, setSoleTraderIdentifier] = useState<SoleTraderIdentifier>(SoleTraderIdentifier.Email)

  const isCompanyIdentifierDisabled = !paymentJourneyData?.paymentJourney.shouldCollectCompanyDetails

  return (
    <form id="verification-form" onSubmit={handleSubmit(onSubmit)}>
      {isCompanyIdentifierDisabled ? (
        <FormControl sx={{ pb: '0' }}>
          <FormLabel sx={{ typography: 'inputLabel' }}>Sole trader's email</FormLabel>
          <Typography data-cy="companyIdentifier" variant="caption2">
            {orderInfo.company.companyIdentifier}
          </Typography>
        </FormControl>
      ) : (
        <>
          <SoleTraderIdentifierSelection
            soleTraderIdentifier={soleTraderIdentifier}
            onSoleTraderIdentifierChanged={setSoleTraderIdentifier}
          />
          <Box sx={{ typography: 'body2', marginTop: theme.spacing(10) }}>
            {soleTraderIdentifier === SoleTraderIdentifier.Email ? (
              <TextInput
                label="Sole trader's email"
                name="email"
                register={register}
                validation={{
                  required: 'Please enter your email address',
                  pattern: {
                    value: emailPattern,
                    message: 'Please enter a valid email address'
                  }
                }}
                error={errors.email}
                style={{ pb: '0' }}
              />
            ) : (
              <PhoneInput
                label="Sole trader's mobile number"
                name="phoneNumber"
                control={control}
                error={errors.phoneNumber}
                validation={{ isValidated: true, errorMessage: 'Please enter a valid phone number' }}
                defaultCountry={defaultCountryCode}
                availableCountries={availableCountries!.map((c) => c.countryCode)}
                disableDropdown={false}
              />
            )}
          </Box>
        </>
      )}

      <CreditCheck />
      <PannelWithDefaultButtons
        marketplaceName={paymentJourneyData?.marketplace.name as string}
        redirectUrl={paymentJourneyData?.paymentJourney.paymentCancelledRedirectUrl as string}
        loading={loading}
        disabled={!form.formState.isValid}
      />
    </form>
  )
}

export default SoleTraderDetails
