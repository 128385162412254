import React, { useEffect } from 'react'
import useFetchCacheData from '../../custom-hooks/useFetchCacheData'
import { useNavigate } from 'react-router-dom'
import Loading from '../../components/common/loading/Loading'
import { useCompleteOrder } from '../../custom-hooks/useCompleteOrder'
import { delay } from '../../utils/delay'

const MfaApproved = () => {
  const navigate = useNavigate()
  const paymentJourneyData = useFetchCacheData()

  const redirectToError = () => {
    navigate('/error', {
      replace: true
    })
  }

  const completeOrderMutation = useCompleteOrder({
    onSuccess: (data) => {
      // 3s delay before redirect
      delay(3000)
        .then(() => {
          window.location.replace(data.marketplaceRedirectUrl)
        })
        .catch(() => redirectToError())
    },
    onError: redirectToError
  })

  useEffect(() => {
    completeOrderMutation.mutate()
  }, [])

  if (!paymentJourneyData) {
    return null
  }

  return (
    <Loading
      header={`The order has been approved`}
      bodyMessage={`We're redirecting you to ${paymentJourneyData.marketplace.name}`}
      addendumMessage={`Please don't close the window.`}
    ></Loading>
  )
}

export default MfaApproved
