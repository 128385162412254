import React from 'react'
import OrderErrors, { ErrorMessage } from './OrderErrors'

const OrderNotFound = () => {
  return (
    <OrderErrors>
      <ErrorMessage>Something went wrong! Please head back to the page you were on.</ErrorMessage>
    </OrderErrors>
  )
}

export default OrderNotFound
