import React from 'react'
import Typography from '@mui/material/Typography'
import SystemErrors from './SystemErrors'
import Link from '@mui/material/Link'

const NotFound = () => {
  return (
    <SystemErrors title="Link not found">
      <Typography variant="errorBody">Hmm… You’ve entered an invalid link. Please try again.</Typography>
      <Typography variant="errorBody">
        If you need any assistance, please contact{' '}
        <Link href="mailto:customersupport@kriya.co" variant="errorBody">
          customersupport@kriya.co
        </Link>{' '}
        .
      </Typography>
    </SystemErrors>
  )
}

export default NotFound
