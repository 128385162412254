import { apiClient } from '../api/apiClient'
import { useMutation, useQueryClient } from 'react-query'
import { PlaceOrderResponse } from '../types/PlaceOrderResponse'
import { mfaSessionKey } from './useMfaSession'
import { AxiosError } from 'axios'

export const updateMfaDecision = async (
  marketplaceOrderId: string,
  token: string,
  recipientId: string,
  decision: string
): Promise<PlaceOrderResponse> => {
  const response = await apiClient.put<PlaceOrderResponse>(
    `paymentjourney/${marketplaceOrderId}/session/${token}/${recipientId}`,
    {
      decision: decision
    }
  )
  return response.data
}

export const useUpdateMfaDecision = (marketplaceOrderId: string, token: string, recipientId: string) => {
  const queryClient = useQueryClient()
  return useMutation(
    async (decision: string): Promise<void> => {
      await updateMfaDecision(marketplaceOrderId, token, recipientId, decision)
    },
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries([mfaSessionKey])
      },
      onError: async (error: AxiosError) => {
        if (error.response?.status === 400 || error.response?.status === 404) {
          await queryClient.invalidateQueries([mfaSessionKey])
        }
      }
    }
  )
}
