import React from 'react'
import OrderErrors, { ErrorMessage } from './OrderErrors'
import useFetchCacheData from '../../custom-hooks/useFetchCacheData'
import { useNavigate } from 'react-router-dom'

const InsufficientFundsOther = () => {
  const paymentJourneyData = useFetchCacheData()
  const navigate = useNavigate()

  if (paymentJourneyData === undefined) {
    navigate('/error', {
      replace: true
    })

    return null
  }

  return (
    <OrderErrors
      marketplaceName={paymentJourneyData.marketplace.name}
      returnToMarketPlaceUrl={paymentJourneyData.paymentJourney.paymentDeclinedRedirectUrl}
    >
      <ErrorMessage>
        Unfortunately, your current spending limit with Kriya Payments is insufficient. Please return to&nbsp;
        {paymentJourneyData.marketplace.name} and choose another payment option to complete your order.
      </ErrorMessage>
    </OrderErrors>
  )
}

export default InsufficientFundsOther
