export const formatDateTime = (dateTime: string) => {
  const formatter = new Intl.DateTimeFormat('en-gb', {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    hour12: true
  })

  return formatter.format(Date.parse(dateTime))
}
