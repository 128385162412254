import { apiClient } from '../api/apiClient'
import { useMutation, useQueryClient } from 'react-query'
import { useNavigate } from 'react-router-dom'
import { AxiosError } from 'axios'
import { fetchOrderKey } from './useOrderInfo'

export const resendMfaSession = async (marketplaceOrderId: string, sessionToken: string): Promise<void> => {
  return await apiClient.post(`paymentjourney/${marketplaceOrderId}/session/${sessionToken}/resend`)
}

export const useResendMfaSession = () => {
  const navigate = useNavigate()
  const queryClient = useQueryClient()

  return useMutation(
    async (request: { marketplaceOrderId: string; sessionToken: string }): Promise<void> => {
      return await resendMfaSession(request.marketplaceOrderId, request.sessionToken)
    },
    {
      onSuccess: async (statusCode) => {
        await queryClient.invalidateQueries(fetchOrderKey)
      },
      onError: (error: AxiosError) => {
        navigate('/error')
      }
    }
  )
}
