import React, { useState } from 'react'
import { UseFormReturn } from 'react-hook-form'
import { CustomerDetailsForm } from '../../types/CustomerDetailsForm'
import { InputModeComponent } from '../../components/autocomplete/InputModeComponent'
import { SearchModeComponent } from '../../components/autocomplete/SearchModeComponent'
import Box from '@mui/material/Box'

type CompanyAndDeliveryProps = {
  form: UseFormReturn<CustomerDetailsForm, any>
  defaultMode?: CompanySearchMode
  availableCountries: { value: string; label: string }[]
}

type CompanySearchMode = 'Search' | 'Input'

const CompanyDetailsSearch: React.FC<CompanyAndDeliveryProps> = ({ form, defaultMode, availableCountries }) => {
  const [mode, setMode] = useState<CompanySearchMode>(defaultMode || 'Search')

  return (
    <Box data-cy="company-details">
      {mode === 'Search' && (
        <SearchModeComponent
          form={form}
          toggleMode={() => {
            setMode('Input')
          }}
        />
      )}
      {mode === 'Input' && <InputModeComponent form={form} availableCountries={availableCountries} />}
    </Box>
  )
}

export default CompanyDetailsSearch
