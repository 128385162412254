import React, { SetStateAction, Dispatch } from 'react'
import { styled } from '@mui/material/styles'
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion'
import Box from '@mui/material/Box'
import Radio from '@mui/material/Radio'
import MuiAccordionSummary from '@mui/material/AccordionSummary'
import MuiAccordionDetails from '@mui/material/AccordionDetails'
import Typography from '@mui/material/Typography'
import theme from '../../assets/theme'
import { PaymentOption } from '../../types/PaymentOption'
import { formatNumber, formatPercent } from '../../utils/amountFormatter'
import { PaymentData } from '../../types/PaymentData'
import { PaymentMethod } from '../../types/enums/PaymentMethod'
import FormLabel from '@mui/material/FormLabel'

const Accordion = styled((props: AccordionProps) => <MuiAccordion disableGutters elevation={0} square {...props} />)`
  border: 1px solid ${theme.palette.common.backgroundGrey};
  :not(:last-child) {
    border-bottom: 0;
  }
  :first-of-type {
    border-radius: 8px 8px 0 0;
  }
  :last-of-type {
    border-radius: 0 0 8px 8px;
  }
  :before {
    display: none;
  }
`

const AccordionSummary = styled(MuiAccordionSummary)`
  min-height: 0px;
  padding: 0;
  .MuiAccordionSummary-content {
    margin: ${theme.spacing(3, 5, 3, 4)};
    display: flex;
    justify-content: space-between;
    ${() => theme.breakpoints.down('mobile')} {
      margin: ${theme.spacing(5, 4)};
    }
  }
`

const AccordionDetails = styled(MuiAccordionDetails)`
  background: ${theme.palette.common.shadow};
  padding: ${theme.spacing(7, 9)};
  ${() => theme.breakpoints.down('mobile')} {
    padding: ${theme.spacing(5, 9)};
  }
  border-top: 1px solid ${theme.palette.common.backgroundGrey};
`
interface Props {
  paymentOptions: Array<PaymentOption>
  paymentDetails: PaymentData
  paymentOption: string
  setPaymentOption: Dispatch<SetStateAction<string>>
}

const PaymentMethodTable: React.FC<Props> = (props: Props) => {
  //Temp fix to ensure page loads.
  const paymentOptions =
    props.paymentOptions.length > 0
      ? props.paymentOptions.map((po) => {
          return {
            ...po,
            description: po.description.replace('_XXX_', formatNumber(po.totalAmount, props.paymentDetails.currency))
          }
        })
      : [
          {
            method: PaymentMethod.MarketPay,
            name: 'PAY30',
            marketPayFee: 0,
            label: 'Pay in 30 days',
            description: 'Pay 0 by bank transfer 30 days after your order is shipped'
          },
          {
            method: PaymentMethod.MarketPay,
            name: 'PAY60',
            marketPayFee: 1500,
            label: 'Pay in 60 days',
            description: 'Pay 1500 by bank transfer 60 days after your order is shipped'
          }
        ]

  const [expanded, setExpanded] = React.useState<string | false>(props.paymentOption || false)

  const handleChange = (panel: string) => (_event: React.SyntheticEvent, isExpanded: boolean) => {
    _event.preventDefault()
    setExpanded(isExpanded ? panel : false)
    if (props.paymentOption !== panel) {
      props.setPaymentOption(panel)
    }
    return false
  }

  return (
    <Box sx={{ p: theme.spacing(9, 0), typography: 'body1' }} id="paymentMethodTable">
      {paymentOptions.map((accordion, index) => {
        const name = accordion.name
        return (
          <Accordion key={index} expanded={expanded === name} onChange={handleChange(name)}>
            <AccordionSummary aria-controls={`${name}-content`} id={`${name}-header`}>
              <Box
                sx={{
                  display: 'flex',
                  height: {
                    xs: '20px',
                    mobile: '24px'
                  }
                }}
              >
                <Radio
                  id={`${name}-${index}-rb`}
                  value={name}
                  required
                  checked={props.paymentOption === name}
                  sx={{
                    p: theme.spacing(0),
                    '& .MuiSvgIcon-root': {
                      fontSize: 20
                    }
                  }}
                  inputProps={{ 'aria-label': name }}
                />
                <FormLabel sx={{ pl: theme.spacing(3), typography: 'body1' }} htmlFor={`${name}-${index}-rb`}>
                  {accordion.label}
                </FormLabel>
              </Box>
            </AccordionSummary>
            <AccordionDetails>{accordion.description}</AccordionDetails>
          </Accordion>
        )
      })}
    </Box>
  )
}

export default PaymentMethodTable
