import React from 'react'
import Typography from '@mui/material/Typography'
import DialogTitle from '@mui/material/DialogTitle'
import CloseIcon from '@mui/icons-material/Close'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import IconButton from '@mui/material/IconButton'
import theme from '../../../assets/theme'
import Button from '@mui/material/Button'
import styled from '@emotion/styled'

const StyledButton = styled(Button)`
  margin-left: ${theme.spacing(4)};
  ${theme.breakpoints.down('sm')} {
    margin-left: 0;
  }
`

export interface ReturnToMarketplaceDialogProps {
  marketplace: string
  disabled: boolean
  redirectUrl?: string
}

function ReturnToMarketplaceDialog(props: ReturnToMarketplaceDialogProps) {
  const [open, setOpen] = React.useState(false)
  const { marketplace } = props

  const handleDialogOpen = () => {
    setOpen(true)
  }

  const handleDialogClose = () => {
    setOpen(false)
  }

  const handleLeaveKriya = () => {
    window.location.replace(props.redirectUrl as string)
  }

  return (
    <>
      <StyledButton variant="text" onClick={handleDialogOpen} disabled={props.disabled} id="return-to-marketplace-btn">
        <Typography variant="body1" sx={{ fontWeight: { mobile: 600 } }}>
          Return to {marketplace}
        </Typography>
      </StyledButton>
      <Dialog
        onClose={handleDialogClose}
        open={open}
        role="dialog"
        aria-labelledby="return-to-marketplace-dialog-title"
        aria-describedby="return-to-marketplace-content"
      >
        <DialogTitle sx={{ padding: theme.spacing(0, 0, 2, 0) }} id="return-to-marketplace-dialog-title">
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography variant="dialogTitle"> Leaving Kriya? </Typography>
            <div>
              <IconButton onClick={handleDialogClose}>
                <CloseIcon fontSize="small" />
              </IconButton>
            </div>
          </div>
        </DialogTitle>
        <DialogContent id="return-to-marketplace-content">
          <Typography
            variant="body1"
            color={theme.palette.common.textGrey700}
            sx={{
              pt: {
                xs: theme.spacing(6),
                mobile: theme.spacing(5)
              }
            }}
          >
            Should you exit Kriya at this moment, any edits you've made won't be preserved.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" sx={{ height: '36px' }} onClick={handleDialogClose}>
            <Typography variant="body2"> Stay and continue </Typography>
          </Button>
          <Button variant="outlined" sx={{ minWidth: '140px', height: '36px' }} onClick={handleLeaveKriya}>
            <Typography variant="body2">Return to {marketplace}</Typography>
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default ReturnToMarketplaceDialog
