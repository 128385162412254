import React, { useEffect } from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { useMfaSession } from '../../custom-hooks/useMfaSession'
import useFetchCacheData from '../../custom-hooks/useFetchCacheData'
import { useNavigate } from 'react-router-dom'

const MfaPollingContent = () => {
  const navigate = useNavigate()
  const paymentJourneyData = useFetchCacheData()

  const mfaParams =
    paymentJourneyData && paymentJourneyData.session?.token
      ? {
          marketplaceOrderId: paymentJourneyData.orderDetails.marketPlaceOrderId,
          sessionToken: paymentJourneyData.session.token
        }
      : null

  const { data: mfaSession, isLoading: isMfaLoading, error: mfaError } = useMfaSession(mfaParams)

  const redirectToError = () => {
    navigate('/error', {
      replace: true
    })
  }

  useEffect(() => {
    if (paymentJourneyData === undefined || paymentJourneyData.session === null) {
      redirectToError()
    }
  }, [paymentJourneyData])

  useEffect(() => {
    if (mfaError) {
      redirectToError()
    }
  }, [mfaError, mfaSession])

  if (isMfaLoading) {
    return null
  }

  if (!paymentJourneyData || !mfaSession) {
    return null
  }

  if (!mfaSession || mfaSession.recipients.length === 0) {
    redirectToError()
    return null
  }

  return (
    <Box id="mfa-polling-content-container" sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <Typography variant="body1">For order verification, we've sent an SMS to:</Typography>
      <ul data-cy="mfa-recipient-list">
        {mfaSession.recipients.map((recipient, index) => (
          <li key={`recipient-${index}`} style={{ textAlign: 'left' }}>
            {recipient.name} ({recipient.destination})
          </li>
        ))}
      </ul>

      <Typography variant="body1">Checkout will resume once we receive their approval.</Typography>
    </Box>
  )
}

export default MfaPollingContent
