import React from 'react'
import Box from '@mui/material/Box'
import { formatNumber } from '../../utils/amountFormatter'
import { Table, TBody, TD, TR } from '../common/table'
import theme from '../../assets/theme'
import { Address } from '../../types/Address'
import { PaymentData } from '../../types/PaymentData'
import Typography from '@mui/material/Typography'
import { formatDateTime } from '../../utils/dateTimeFormatter'

export type CartData = {
  marketPlaceName: string
  paymentDetails: PaymentData
  deliveryAddress?: Address
  orderCreatedOn: string
}

export const CartTable = (cartData: CartData) => {
  return (
    <>
      <Typography data-cy="cart-heading" variant="caption">
        Your Cart
      </Typography>
      <Box sx={{ typography: 'body1', pt: theme.spacing(7) }}>
        <Table id="buyer-cart-table">
          <TBody>
            <TR>
              <TD>Merchant:</TD>
              <TD>{cartData.marketPlaceName}</TD>
            </TR>
            <TR>
              <TD>Amount:</TD>
              <TD>{formatNumber(cartData.paymentDetails.amount, cartData.paymentDetails.currency)}</TD>
            </TR>
            <TR>
              <TD>Order created on:</TD>
              <TD>{formatDateTime(cartData.orderCreatedOn)}</TD>
            </TR>
            {cartData.deliveryAddress ? (
              <TR>
                <TD>Delivery address:</TD>
                <TD>
                  {cartData.deliveryAddress.addressLine1}, {cartData.deliveryAddress.city}{' '}
                  {cartData.deliveryAddress.postCode}
                </TD>
              </TR>
            ) : (
              <></>
            )}
          </TBody>
        </Table>
      </Box>
    </>
  )
}
