import Typography from '@mui/material/Typography'
import React, { ReactElement } from 'react'
import AccordionPanel from '../accordion'
import Link from '@mui/material/Link'
import styled from '@emotion/styled'
import theme from '../../assets/theme'

type FaqParams = {
  marketplaceName: string
}

const Faq = ({ marketplaceName }: FaqParams) => {
  const items: { title: string; content?: ReactElement }[] = [
    {
      title: `Why is ${marketplaceName} offering Kriya Payments?`,
      content: (
        <Typography variant="body1">
          {marketplaceName} is partnering with the best providers to give you more flexible credit options when paying
          for their services and more time to pay. Kriya Payments is an innovative way to help you pay later.
        </Typography>
      )
    },
    {
      title: 'How does Kriya Payments work?',
      content: (
        <Typography variant="body1">
          When you use Kriya Payments at checkout you choose one of the available payment methods. We'll confirm your
          payment choice with you via email, along with your agreed payment plan and our account details. We'll remind
          you when it's time to pay and you can organise via bank transfer.
        </Typography>
      )
    },
    {
      title: 'Will this affect my credit score?',
      content: (
        <Typography variant="body1">
          <p>
            Before you can start paying later with Kriya, we’ll perform a soft credit search on your organisation to
            check if you’re eligible. These kinds of searches have no impact at all on your credit score. If you pass
            this, Kriya Payments will be activated on your account.
          </p>
          <p>
            The first time you choose to pay with Kriya at checkout, a hard search will be made (this will only happen
            on the first purchase). The search will appear on your credit report. Multiple hard searches in a short
            period of time may affect your credit score.
          </p>
        </Typography>
      )
    },
    {
      title: 'What does using Kriya Payments mean for my organisation?',
      content: (
        <Typography variant="body1">
          <p>Kriya Payments</p>
          <p>
            Kriya Payments enables your supplier ({marketplaceName}) to offer you a number of payment options at online
            checkout which may include, but are not limited to, pay in 30 days, pay in 60 days or pay in instalments.
          </p>
          <p>
            When you choose to pay with Kriya Payments, Kriya Payments will, on behalf of your supplier, undertake some
            background checks to determine your eligibility for Kriya Payment options.
          </p>
          <p>
            If you elect to pay your supplier with Kriya Payments, you will be provided a summary of your payment terms
            outlining when you need to pay for your purchased goods or services and, if applicable, the number and
            timing of your instalment payments. You will also be informed into which bank account to make the payments.
            This summary will be provided to you as a widget on the supplier’s check-out webpage for your confirmation
            prior to you confirming your purchase with Kriya and will be emailed to you following every purchase.
          </p>
          <p>
            Your supplier remains fully responsible for the quality and delivery of the goods or services as per their
            terms and conditions. Should there be any disputes or issues with respect to the goods or services ordered
            by you, you should contact your supplier. In the event that you are eligible for reimbursement of any
            payments under the supplier’s terms and conditions, such reimbursements will be reflected in a corresponding
            reduction in your remaining scheduled payments. Should you miss any of your payments set out in your payment
            schedule, you will be contacted by Kriya, on behalf of your supplier, to help manage your late payments.
          </p>
          <p>
            Please get in touch via <Link href="mailto:payments@kriya.co">payments@kriya.co</Link> if you have any
            questions about your payment.
          </p>
        </Typography>
      )
    }
  ]

  const StyledHeader = styled(Typography)`
    padding: ${theme.spacing(8, 0, 8, 0)};
  `

  return (
    <>
      <StyledHeader>
        <Typography data-cy="faq-title" variant="h1">
          FAQ
        </Typography>
      </StyledHeader>

      <AccordionPanel items={items} data-cy="kriya-faq" />
    </>
  )
}

export default Faq
