import React, { useRef, useEffect } from 'react'
import { styled } from '@mui/material/styles'
import theme from '../../../assets/theme'

const StyledWrapper = styled('div')`
  width: 100%;
  &:focus {
    outline-offset: 2px;
    outline-color: ${theme.palette.common.black};
  }
  & div.trustpilot-widget-logo {
    ${theme.breakpoints.down('tablet')} {
      padding-left: 0px !important;
    }
  }
`

const TrustPilotBox = () => {
  const trustBoxRef = useRef(null)
  useEffect(() => {
    // If window.Trustpilot is available it means that we need to load the TrustBox from our ref.
    // If it's not, it means the script you pasted into <head /> isn't loaded  just yet.
    // When it is, it will automatically load the TrustBox.
    //@ts-ignore => Trustpilot is an object whereas loadFromElement is a function, however no idea about the function return type
    if (window.Trustpilot) window.Trustpilot.loadFromElement(trustBoxRef.current, true)
  }, [])

  return (
    <StyledWrapper onClick={() => (window.location.href = 'https://uk.trustpilot.com/review/kriya.co')}>
      <div
        id="trustpilotWidget"
        ref={trustBoxRef}
        className="trustpilot-widget-logo"
        data-locale="en-GB"
        data-template-id="5406e65db0d04a09e042d5fc"
        data-businessunit-id="63a219862c4ca815c8995bbd"
        data-style-width="100%"
        data-theme="light"
      >
        <a href="https://uk.trustpilot.com/review/kriya.co" target="_blank" rel="noopener noreferrer">
          Trustpilot
        </a>
      </div>
    </StyledWrapper>
  )
}

export default TrustPilotBox
