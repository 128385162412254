import Container from '@mui/material/Container'
import React from 'react'
import theme from '../../assets/theme'
import styled from '@emotion/styled'
import Box from '@mui/material/Box'
import StyledBox from './Panel'

const SyledContainer = styled(Container)`
  width: 560px;
  padding: ${theme.spacing(0, 8)};
  ${theme.breakpoints.down('sm')} {
    width: 100%;
    padding: ${theme.spacing(0, 5)};
  }
`
const StyledLayoutContainer = ({ children }: { children: React.ReactNode }) => {
  return <SyledContainer disableGutters={true}> {children} </SyledContainer>
}

const StyledButtonsContainer = styled(Box)`
  display: flex;
  justify-content: left;
  margin-top: ${theme.spacing(11)};
  ${theme.breakpoints.down('sm')} {
    justify-content: center;
    flex-direction: column;
    margin-top: ${theme.spacing(13)};
  }
`

const StyledErrorPanel = styled(StyledBox)`
  text-align: left;
  padding: ${theme.spacing(12, 13)};
  min-width: 448px;
  margin-top: ${theme.spacing(14)};
  border-radius: 8px;
  border: 1px solid var(--grey-200, ${theme.palette.common.backgroundGrey});
  background: var(--white, ${theme.palette.common.white});
  box-shadow: 8px 8px var(--midnight-8, ${theme.palette.common.shadow});
  ${theme.breakpoints.down('sm')} {
    margin-top: ${theme.spacing(11)};
    padding: ${theme.spacing(8)};
    text-align: left;
    min-width: 278px;
    box-shadow: 4px 4px var(--midnight-8, ${theme.palette.common.shadow});
  }
`

export { StyledLayoutContainer, StyledButtonsContainer, StyledErrorPanel }
