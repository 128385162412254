import React, { useMemo } from 'react'
import SpendingLimitTable from '../payment/SpendingLimitTable'
import OrderErrors, { ErrorMessage, SemiBoldSpan } from './OrderErrors'
import useFetchCacheData from '../../custom-hooks/useFetchCacheData'
import { useNavigate } from 'react-router-dom'
import { formatNumber } from '../../utils/amountFormatter'

const InsufficientFundsKycCheckRequired = () => {
  const paymentJourneyData = useFetchCacheData()
  const navigate = useNavigate()

  const totalSpendingLimit = useMemo(() => {
    if (paymentJourneyData === undefined) return ''
    const totalSpendingLimit = paymentJourneyData.buyerRiskDecision.totalSpendingLimit
    const currency = paymentJourneyData.paymentDetails.payment.currency
    return formatNumber(totalSpendingLimit, currency)
  }, [paymentJourneyData])

  if (paymentJourneyData === undefined) {
    navigate('/error', {
      replace: true
    })

    return null
  }

  return (
    <OrderErrors
      marketplaceName={paymentJourneyData.marketplace.name}
      returnToMarketPlaceUrl={paymentJourneyData.paymentJourney.paymentDeclinedRedirectUrl}
    >
      <ErrorMessage>
        Regrettably, following our assessment, your total spending limit is {totalSpendingLimit}. However, your existing
        available spending limit is inadequate for this order, as you've used the limit with other merchants.
      </ErrorMessage>
      <ErrorMessage>
        <SemiBoldSpan>
          Please return to {paymentJourneyData.marketplace.name} and select an alternative payment option to complete
          your order.
        </SemiBoldSpan>
      </ErrorMessage>
      <ErrorMessage>
        Since your spending limit hasn't been activated for {paymentJourneyData.marketplace.name},&nbsp;
        <SemiBoldSpan>
          we suggest completing the director ID verification on {paymentJourneyData.marketplace.name}'s website.&nbsp;
        </SemiBoldSpan>
        Initiating this process will enable you to expedite subsequent checkouts more efficiently once the ID
        verification is successfully completed.
      </ErrorMessage>

      {paymentJourneyData.buyerRiskDecision.availableLimit ? (
        <SpendingLimitTable
          availableLimit={paymentJourneyData.buyerRiskDecision.availableLimit}
          paymentDetails={paymentJourneyData.paymentDetails.payment}
        />
      ) : null}
    </OrderErrors>
  )
}

export default InsufficientFundsKycCheckRequired
