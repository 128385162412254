import React from 'react'
import { FieldValues, FieldError, Control, Controller } from 'react-hook-form'
import ErrorText from './FormErrors'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import FormLabel from '@mui/material/FormLabel'
import theme from '../../assets/theme'
import Select from '@mui/material/Select'

interface Props {
  control: Control<FieldValues, any>
  name: string
  label: string
  optional?: boolean
  validation?: { [key: string]: string | boolean | {} }
  error?: FieldError
  options: { value: string; label: string }[]
}

const FormSelect: React.FC<Props> = ({ control, name, label, validation = {}, error, options }) => {
  return (
    <Controller
      control={control}
      name={name}
      rules={validation}
      defaultValue=""
      render={({ field: { onChange, onBlur, value } }) => (
        <FormControl>
          <FormLabel id={`${name}_label`} sx={{ pb: theme.spacing(2) }}>
            {label}
          </FormLabel>
          <Select
            variant="outlined"
            onChange={onChange}
            value={value}
            data-cy={name}
            onBlur={onBlur}
            aria-labelledby={`${name}_label`}
            disabled={options.length === 1}
            MenuProps={{
              PaperProps: {
                style: {
                  maxHeight: 300
                }
              }
            }}
          >
            {options.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
          {error && <ErrorText id={name}>{error.message}</ErrorText>}
        </FormControl>
      )}
    />
  )
}

export default FormSelect
