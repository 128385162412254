import React from 'react'
import Typography from '@mui/material/Typography'
import theme from '../../../assets/theme'

type HeadingProps = {
  dataCy?: string
  children: string
}

export const H1 = (props: HeadingProps) => {
  return (
    <Typography
      variant="h1"
      data-cy={props.dataCy}
      sx={{
        pb: {
          mobile: theme.spacing(7),
          xs: theme.spacing(5)
        }
      }}
    >
      {props.children}
    </Typography>
  )
}
