import React from 'react'
import { CartTable } from '../../components/cartTable/CartTable'
import { PaymentJourneyInfo } from '../../types/PaymentJourneyInfo'
import Typography from '@mui/material/Typography'
import Faq from '../../components/faq'
import styled from '@emotion/styled'
import theme from '../../assets/theme'
import PrivacyPolicy from '../../components/privacyPolicy'

const StyledHeader = styled(Typography)`
  padding-bottom: ${theme.spacing(10)};
  ${theme.breakpoints.down('sm')} {
    padding-bottom: ${theme.spacing(11)};
  }
`
type CustomerDetailsWrapperProps = {
  data: PaymentJourneyInfo
  children?: React.ReactNode
}
const StyledCustomerDetailsContainer = styled.div`
  padding: ${theme.spacing(11, 0, 13, 0)};
`
const CustomerDetailsWrapper: React.FC<CustomerDetailsWrapperProps> = ({ data, children }) => {
  return (
    <>
      <StyledHeader variant="h1">Please complete the form to continue</StyledHeader>
      <CartTable
        marketPlaceName={data.marketplace.name}
        paymentDetails={data.paymentDetails.payment}
        deliveryAddress={data.deliveryDetails?.address}
        orderCreatedOn={data.orderDetails.createdOn}
      />

      <StyledCustomerDetailsContainer>{children}</StyledCustomerDetailsContainer>
      <PrivacyPolicy />

      <Faq marketplaceName={data.marketplace.name} />
    </>
  )
}

export default CustomerDetailsWrapper
