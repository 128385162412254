import { Components } from '@mui/material'
import createTheme, { Theme } from '@mui/material/styles/createTheme'

const midnight = '#00283C'
const textGrey900 = '#090909'
const uiErrorRedColor = '#DC3545'
const backgroundGrey100 = '#F0F0F0'
const brandTeal = '#0039B0'
const textBoxHover = '#FAFAFA'
const backgroundGrey = '#DEDEDE'
const putty = '#FFF5E3'
const fonts = "'Matter', sans-serif"
const white = '#FFFFFF'

let theme = createTheme({
  //--------0  1  2  3  4   5   6   7   8   9   10  11  12  13  14   15   16
  spacing: [0, 2, 4, 8, 12, 16, 20, 24, 32, 40, 48, 64, 80, 96, 160, 192, 240],
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
      mobile: 500,
      tablet: 720,
      desktop: 1200
    }
  },
  palette: {
    primary: {
      main: midnight
    },
    text: {
      primary: '#222222'
    },
    common: {
      brandPrimaryTeal: '#00CDBE',
      brandTeal: brandTeal,
      backgroundTeal50: '#DFF6F5',
      backgroundMain: '#F5F5F5',
      backgroundPrimaryGrey: '#DCD7D7',
      backgroundGrey: backgroundGrey,
      backgroundGrey100: backgroundGrey100,
      backgroundWhite: '#FFFFFF',
      linkOnWhite: '#090909',
      textGrey900: textGrey900,
      textGrey700: '#3C3C3C',
      buttonBackgroundHover: '#32627F',
      buttonFocusStroke: '#00CF3B',
      buttonBackgroundActive: '#3F7090',
      buttonColorOnDisabled: '#C2C2C2',
      uiError: uiErrorRedColor,
      textBoxHover: textBoxHover,
      textGrey600: '#606060',
      visitedLink: '#7100E1',
      shadow: 'rgba(0, 40, 60, 0.08)',
      chipBackground: 'rgba(0, 224, 67, 0.12)',
      navBarText: putty,
      navigationLinkActive: '#B8D6EB',
      navigationLinkVisited: '#EDBDFE',
      menuBackground: '#003B59'
    }
  },
  typography: {
    h1: {
      fontFamily: fonts,
      fontSize: '20px',
      fontWeight: '500',
      lineHeight: '28px',
      letterSpacing: '0'
    },
    h2: {
      fontFamily: fonts,
      fontSize: '16px',
      fontWeight: '600',
      lineHeight: '22px'
    },
    body1: {
      fontFamily: fonts,
      fontWeight: '400',
      fontSize: '16px',
      lineHeight: '24px',
      letterSpacing: '0'
    },
    body2: {
      fontFamily: fonts,
      fontWeight: '400',
      fontSize: '14px',
      lineHeight: '20px',
      letterSpacing: 0
    },
    caption: {
      fontFamily: fonts,
      fontWeight: '600',
      fontSize: '16px',
      lineHeight: '22px',
      letterSpacing: '0.01071em'
    },
    caption2: {
      fontFamily: fonts,
      fontWeight: '600',
      fontSize: '16px',
      lineHeight: '24px'
    },
    label: {
      fontFamily: fonts,
      fontWeight: '400',
      fontSize: '12px',
      lineHeight: '16px'
    },
    mobile: {
      fontSize: '12px',
      lineHeight: '0.33'
    },
    dialogTitle: {
      fontFamily: fonts,
      fontWeight: '500',
      fontStyle: 'normal',
      fontSize: '26px',
      lineHeight: '36px',
      letterSpacing: 0,
      color: textGrey900
    },
    errorHeading: {
      fontFamily: fonts,
      fontSize: '32px',
      fontWeight: '500',
      lineHeight: '40px',
      color: uiErrorRedColor
    },
    errorBody: {
      fontFamily: fonts,
      fontSize: '20px',
      fontWeight: '400',
      lineHeight: '28px',
      color: textGrey900
    },
    subtitle2: {
      fontFamily: fonts,
      fontSize: '14px',
      fontWeight: '600',
      lineHeight: '20px'
    },
    helperText: {
      fontFamily: fonts,
      fontSize: '12px',
      fontWeight: '700',
      lineHeight: '16px',
      color: uiErrorRedColor
    },
    inputLabel: {
      fontFamily: fonts,
      fontSize: '14px',
      fontWeight: '400',
      lineHeight: '18px',
      color: textGrey900
    },
    inputText: {
      fontFamily: fonts,
      fontSize: '16px',
      fontWeight: '400',
      lineHeight: '24px'
    },
    progressBarCircleLabel: {
      fontFamily: fonts,
      fontWeight: '400',
      fontSize: '14px',
      lineHeight: '18px'
    },
    loadingPageHeader: {
      fontFamily: fonts,
      fontWeight: '500',
      fontStyle: 'normal',
      fontSize: '26px',
      lineHeight: '36px',
      color: textGrey900
    },
    navigationLink: {
      fontSize: '12px',
      fontWeight: 500,
      lineHeight: '12px'
    },
    navingationLinkAddOn: {
      fontSize: '10px',
      fontWeight: 400,
      lineHeight: '20px',
      color: white
    }
  }
})

const components: Components<Theme> = {
  MuiAppBar: {
    styleOverrides: {
      root: {
        backgroundColor: `${theme.palette.primary.main} !important`,
        height: '80px',
        [theme.breakpoints.down('sm')]: {
          height: '64px'
        }
      }
    }
  },
  MuiAutocomplete: {
    styleOverrides: {
      root: {
        'label + &': {
          marginTop: theme.spacing(2)
        }
      },
      inputRoot: {
        padding: theme.spacing(4),
        '& .MuiAutocomplete-input': {
          padding: theme.spacing(0, 2)
        }
      }
    }
  },
  MuiAccordion: {
    defaultProps: {
      disableGutters: true,
      square: true,
      elevation: 0
    },
    styleOverrides: {
      root: {
        borderTop: `1px solid ${theme.palette.divider}`,
        borderBottom: `1px solid ${theme.palette.divider}`,
        ':not(:last-child)': {
          borderBottom: 0
        },
        ':before': {
          display: 'none'
        }
      }
    }
  },
  MuiAccordionSummary: {
    styleOverrides: {
      root: {
        padding: theme.spacing(5),
        border: '2px solid transparent',
        '& .MuiAccordionSummary-content': {
          margin: theme.spacing(0)
        },
        '& .MuiAccordionSummary-expandIconWrapper': {
          color: theme.palette.common.textGrey900
        },
        ':hover': {
          background: theme.palette.common.backgroundGrey
        },
        ':focus-within': {
          background: theme.palette.common.white,
          border: `2px solid ${theme.palette.common.buttonFocusStroke}`
        },
        [theme.breakpoints.down('mobile')]: {
          padding: theme.spacing(3, 5)
        }
      }
    }
  },
  MuiAccordionDetails: {
    styleOverrides: {
      root: {
        padding: theme.spacing(3, 5, 7, 5)
      }
    }
  },
  MuiButton: {
    defaultProps: {
      disableTouchRipple: true,
      disableFocusRipple: true
    },
    styleOverrides: {
      root: ({ ownerState }) => ({
        ...(ownerState.variant !== 'navigation' && {
          textTransform: 'none',
          fontFamily: theme.typography.body2.fontFamily,
          fontSize: theme.typography.body2.fontSize,
          lineHeight: theme.typography.body2.lineHeight,
          fontWeight: theme.typography.body2.fontWeight,
          transition: 'box-shadow 180ms ease-in'
        }),
        ...(ownerState.variant === 'navigation' && {
          padding: '0 !important'
        }),
        ...(ownerState.variant === 'text' && {
          color: theme.palette.common.linkOnWhite,
          lineHeight: theme.typography.body2.lineHeight,
          fontSize: theme.typography.body2.fontSize,
          boxShadow: '0 0 0 3px transparent',
          padding: theme.spacing(4, 7),
          borderRadius: '6px',
          textDecoration: 'underline',
          ':hover': {
            background: theme.palette.common.backgroundGrey100,
            textDecoration: 'underline'
          },
          ':focus': {
            boxShadow: `0 0 0 3px ${theme.palette.common.buttonFocusStroke}`,
            background: theme.palette.common.white,
            outline: 'none'
          },
          ':active': {
            background: theme.palette.common.backgroundPrimaryGrey,
            boxShadow: '0 0 0 3px transparent'
          },
          ':disabled': {
            color: theme.palette.common.buttonColorOnDisabled,
            background: theme.palette.common.backgroundWhite
          },
          [theme.breakpoints.down('mobile')]: {
            width: '100%',
            padding: theme.spacing(4, 6)
          }
        }),
        ...((ownerState.variant === 'outlined' || ownerState.variant === 'contained') && {
          padding: theme.spacing(3, 4),
          borderRadius: '100px',
          height: '38px',
          ':hover': {
            background: theme.palette.common.buttonBackgroundHover,
            color: theme.palette.common.white
          },
          ':focus': {
            background: theme.palette.primary.main,
            boxShadow: `0 0 0 3px ${theme.palette.common.buttonFocusStroke}`,
            color: theme.palette.common.white
          },
          ':active': {
            background: theme.palette.common.buttonBackgroundActive,
            color: theme.palette.common.white
          },
          [theme.breakpoints.down('mobile')]: {
            fontSize: theme.typography.mobile.fontSize,
            padding: theme.spacing(3)
          }
        }),
        ...(ownerState.variant === 'contained' && {
          background: theme.palette.primary.main,
          border: '3px solid transparent',
          ':disabled': {
            color: theme.palette.common.buttonColorOnDisabled,
            background: theme.palette.common.backgroundGrey100,
            boxShadow: `0 0 0 3px ${theme.palette.common.backgroundGrey100}`
          }
        }),
        ...(ownerState.variant === 'outlined' && {
          boxSizing: 'border-box',
          minWidth: '110px',
          background: theme.palette.common.backgroundWhite,
          border: `1px solid ${theme.palette.primary.main}`,
          ':disabled': {
            color: theme.palette.common.buttonColorOnDisabled,
            border: `1px solid ${theme.palette.common.buttonColorOnDisabled}`,
            boxShadow: `0 0 0 3px ${theme.palette.common.backgroundWhite}`,
            background: theme.palette.common.backgroundWhite
          }
        })
      })
    }
  },
  MuiDialog: {
    styleOverrides: {
      root: {
        '& .MuiDialog-paper': {
          padding: theme.spacing(8, 7),
          borderRadius: '8px',
          width: '464px',
          height: '228px',
          boxSizing: 'border-box',
          [theme.breakpoints.down('mobile')]: {
            padding: theme.spacing(7),
            margin: theme.spacing(5),
            width: '342px',
            height: '276px'
          }
        }
      }
    }
  },
  MuiDialogActions: {
    styleOverrides: {
      root: {
        padding: theme.spacing(6, 0, 0),
        [theme.breakpoints.down('mobile')]: {
          padding: theme.spacing(0),
          justifyContent: 'center'
        }
      }
    }
  },
  MuiDialogContent: {
    styleOverrides: {
      root: {
        padding: theme.spacing(0)
      }
    }
  },
  MuiFormControl: {
    defaultProps: {
      variant: 'standard'
    },
    styleOverrides: {
      root: {
        width: '100%',
        paddingBottom: theme.spacing(8)
      }
    }
  },
  MuiFormLabel: {
    styleOverrides: {
      root: {
        fontSize: theme.typography.inputLabel.fontSize,
        lineHeight: theme.typography.inputLabel.lineHeight,
        color: theme.typography.inputLabel.color,
        '&.Mui-focused': {
          color: theme.typography.inputLabel.color
        }
      }
    }
  },
  MuiFormControlLabel: {
    styleOverrides: {
      root: {
        paddingTop: theme.spacing(7)
      }
    }
  },
  MuiIconButton: {
    defaultProps: {
      disableRipple: true
    },
    styleOverrides: {
      root: {
        color: theme.palette.common.textGrey900
      }
    }
  },
  MuiMenu: {
    defaultProps: {
      transformOrigin: {
        vertical: -8,
        horizontal: 14
      }
    },
    styleOverrides: {
      root: {
        '&.phoneNumber': {
          maxHeight: '200px !important'
        }
      }
    }
  },
  MuiMenuItem: {
    styleOverrides: {
      root: {
        '.MuiPhoneNumber-flagIcon': {
          display: 'flex'
        },
        '.dial-code': {
          display: 'block',
          paddingLeft: theme.spacing(3)
        }
      }
    }
  },
  MuiInputAdornment: {
    styleOverrides: {
      root: {
        svg: {
          maxWidth: '25px'
        }
      }
    }
  },
  MuiOutlinedInput: {
    styleOverrides: {
      root: {
        input: {
          '.chevronDown &': {
            borderLeft: `1px solid ${backgroundGrey}`
          },
          '.chevronUp &': {
            borderLeft: `1px solid ${backgroundGrey}`
          }
        }
      }
    }
  },
  MuiLink: {
    defaultProps: {
      variant: 'subtitle2'
    },
    styleOverrides: {
      root: ({ ownerState }) => ({
        border: '1px solid transparent',
        ':focus': {
          color: theme.palette.primary.main,
          outline: 'none',
          borderRadius: '4px'
        },
        ...(ownerState.variant !== 'navigationLink' && {
          color: theme.palette.common.linkOnWhite,
          textDecoration: 'underline',
          '&:focus': {
            border: `1px solid ${theme.palette.common.buttonFocusStroke}`
          },
          ':active': {
            color: theme.palette.primary.main
          },
          ':visited': {
            color: theme.palette.common.visitedLink
          }
        }),
        ...(ownerState.variant === 'navigationLink' && {
          textDecoration: 'none',
          color: theme.palette.common.white,
          ':active': {
            color: theme.palette.common.navigationLinkActive
          },
          '&:focus': {
            color: theme.palette.common.navigationLinkActive
          },
          ':visited': {
            color: theme.palette.common.navigationLinkVisited
          }
        })
      })
    }
  },
  MuiTextField: {
    styleOverrides: {
      root: {
        'label + &': {
          marginTop: theme.spacing(2)
        },
        paddingBottom: theme.spacing(0),
        outline: 'none',
        backgroundColor: theme.palette.common.white,
        position: 'relative',
        borderRadius: '8px',
        fontSize: theme.typography.inputText.fontSize,
        lineHeight: theme.typography.inputText.lineHeight,
        width: '100%',
        ':focus': {
          borderColor: theme.palette.common.buttonBackgroundActive
        },
        ':hover': {
          backgroundColor: theme.palette.common.textBoxHover
        },
        '::placeholder': {
          color: theme.palette.common.textGrey600
        },
        '& .MuiInputBase-input': {
          padding: theme.spacing(4)
        }
      }
    }
  },
  MuiSelect: {
    styleOverrides: {
      select: {
        borderRadius: '8px',
        padding: theme.spacing(4)
      },
      nativeInput: {
        fontSize: theme.typography.inputText.fontSize,
        lineHeight: theme.typography.inputText.lineHeight
      }
    }
  },
  MuiPaper: {
    styleOverrides: {
      root: {
        '.navbar > &': {
          backgroundColor: `${theme.palette.common.menuBackground} !important`,
          minWidth: '100% !important',
          height: '100%',
          maxHeight: 'calc(100% - 64px) !important',
          maxWidth: '100% !important',
          borderRadius: 0,
          left: '0px !important',
          top: '64px !important'
        }
      }
    }
  }
}

theme = createTheme(theme, {
  components: components,
  typography: {
    h1: {
      [theme.breakpoints.down('sm')]: {
        fontSize: '20px',
        lineHeight: '28px',
        textTransform: 'none',
        fontWeight: '400'
      }
    },
    body1: {
      [theme.breakpoints.down('sm')]: {
        fontSize: '14px',
        lineHeight: '20px'
      }
    },
    caption2: {
      [theme.breakpoints.down('sm')]: {
        fontSize: '14px',
        lineHeight: '20px'
      }
    },
    errorHeading: {
      [theme.breakpoints.down('sm')]: {
        fontSize: '20px',
        lineHeight: '28px'
      }
    },
    errorBody: {
      [theme.breakpoints.down('sm')]: {
        fontSize: '14px',
        lineHeight: '20px'
      }
    },
    loadingPageHeader: {
      [theme.breakpoints.down('sm')]: {
        fontFamily: fonts,
        fontWeight: '600',
        fontStyle: 'normal',
        fontSize: '16px',
        lineHeight: '22px',
        color: textGrey900
      }
    }
  }
})

export default theme
