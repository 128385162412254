import React from 'react'
import theme from '../../../assets/theme'
import styled from '@emotion/styled'

export interface LogoProps {
  marketplace: {
    logoUri: string | undefined
    name: string
  }
}

interface LogoWrapperProps {
  hasLogo: boolean
}

const LogoWrapper = styled.div<LogoWrapperProps>`
  padding: ${({ hasLogo }) => (hasLogo ? theme.spacing(11, 0, 11, 0) : theme.spacing(11, 0, 0, 0))};

  ${theme.breakpoints.down('sm')} {
    padding: ${({ hasLogo }) => (hasLogo ? theme.spacing(8, 0, 10, 0) : theme.spacing(8, 0, 0, 0))};
  }
`
const StyledLogo = styled.img`
  max-width: 200px;
  max-height: 50px;

  ${theme.breakpoints.down('sm')} {
    max-width: 144px;
    max-height: 36px;
  }

  padding: ${theme.spacing(1)};
`

function Logo({ marketplace }: LogoProps) {
  const hasLogo = Boolean(marketplace.logoUri)
  return (
    <LogoWrapper id="logo-wrapper" hasLogo={hasLogo}>
      {marketplace.logoUri && (
        <StyledLogo id="logo" src={marketplace.logoUri} alt={`${marketplace.name} logo`}></StyledLogo>
      )}
    </LogoWrapper>
  )
}

export default Logo
