import React from 'react'
import OrderErrors, { ErrorMessage } from './OrderErrors'
import useFetchCacheData from '../../custom-hooks/useFetchCacheData'
import { useNavigate } from 'react-router-dom'

const Ineligible = () => {
  const paymentJourneyData = useFetchCacheData()
  const navigate = useNavigate()

  if (paymentJourneyData === undefined) {
    navigate('/error', {
      replace: true
    })

    return null
  }

  return (
    <OrderErrors
      marketplaceName={paymentJourneyData.marketplace.name}
      returnToMarketPlaceUrl={paymentJourneyData.paymentJourney.paymentDeclinedRedirectUrl}
    >
      <ErrorMessage>Your organisation didn't meet our eligibility criteria.</ErrorMessage>
      <ErrorMessage>
        If you are completing the checkout for an order, please go back to {paymentJourneyData.marketplace.name} and use
        a different payment method to finalise your purchase.
      </ErrorMessage>
    </OrderErrors>
  )
}

export default Ineligible
