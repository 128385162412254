import { useQuery } from 'react-query'
import { useParams, useSearchParams } from 'react-router-dom'
import { apiClient } from '../api/apiClient'
import { retry } from '../api/apiBaseUrl'
import { PaymentJourneyInfo } from '../types/PaymentJourneyInfo'
export const fetchOrderKey = 'fetch-order'

export const fetchOrderDetails = async (shortCode: string, token: string | null): Promise<PaymentJourneyInfo> => {
  let url = `/paymentjourney/${shortCode}`

  if (token !== null) {
    url = `${url}?token=${token}`
  }

  const response = await apiClient.get<PaymentJourneyInfo>(url)
  return response.data
}

export const useOrderInfo = () => {
  const { shortCode } = useParams()
  const [searchParams] = useSearchParams()
  const token = searchParams.get('token')

  const { data, isLoading, error, isSuccess } = useQuery<PaymentJourneyInfo, Error>(
    [fetchOrderKey, shortCode],
    () => fetchOrderDetails(shortCode as string, token),
    { retry, refetchOnWindowFocus: false }
  )

  return { data, isLoading, error, isSuccess }
}
