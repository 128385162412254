import React from 'react'
import Link from '@mui/material/Link'
import Box from '@mui/material/Box'
import theme from '../../assets/theme'
import styled from '@emotion/styled'

const StyledLink = styled(Link)`
  &.MuiLink-root {
    color: ${theme.palette.common.textGrey900};
  }
`

const PrivacyPolicy = () => {
  return (
    <>
      <Box sx={{ typography: 'body2', marginBottom: theme.spacing(13) }}>
        We're commited to handling your data with the highest standards of privacy. For more details please review our
        {` `}
        <StyledLink
          variant="body2"
          href="https://www.kriya.co/privacy-policy"
          data-cy="privacy-policy-link"
          aria-label="Review Privacy Policy"
          target="_blank"
        >
          Privacy Policy
        </StyledLink>
      </Box>
    </>
  )
}

export default PrivacyPolicy
