import React from 'react'
import { StyledButtonsContainer } from '../../layout/Container'
import { StyledLoadingButton } from './Buttons'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import ReturnToMartketplaceDialog from '../returnToMarketplace'

export type PannelWithDefaultButtonsProps = {
  marketplaceName: string
  disabled: boolean
  loading: boolean
  redirectUrl?: string
  onClick?: React.MouseEventHandler<HTMLButtonElement>
}

const PannelWithDefaultButtons = ({
  marketplaceName,
  redirectUrl,
  disabled,
  loading,
  onClick
}: PannelWithDefaultButtonsProps) => {
  return (
    <StyledButtonsContainer>
      <StyledLoadingButton
        type="submit"
        endIcon={<ChevronRightIcon />}
        id="continue-button"
        loading={loading}
        loadingPosition="end"
        onClick={onClick}
        variant="contained"
        disabled={disabled}
      >
        Continue
      </StyledLoadingButton>
      <ReturnToMartketplaceDialog
        marketplace={marketplaceName}
        disabled={loading}
        redirectUrl={redirectUrl}
      ></ReturnToMartketplaceDialog>
    </StyledButtonsContainer>
  )
}

export default PannelWithDefaultButtons
