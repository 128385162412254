export enum MfaSessionState {
  Approved = 'Approved',
  Rejected = 'Rejected',
  Expired = 'Expired',
  Created = 'Created',
  Pending = 'Pending',
  Cancelled = 'Cancelled'
}

export const IsMfaSessionInProgress = (state: MfaSessionState) =>
  state === MfaSessionState.Created || state === MfaSessionState.Pending

export const IsMfaSessionCompleted = (state: MfaSessionState) =>
  state === MfaSessionState.Approved || state === MfaSessionState.Rejected
