import React from 'react'
import { useForm } from 'react-hook-form'
import CompanyDetails from './CompanyDetails'
import { useUpdatePaymentJourneyInfo } from '../../custom-hooks/useUpdatePaymentJourney'
import TextInput from '../../components/input/TextInput'
import PhoneInput from '../../components/input/PhoneInput/PhoneInput'
import { emailPattern } from '../../components/input/emailRegExp'
import { User } from '../../types/User'
import CompanyDetailsSearch from './CompanyDetailsSearch'
import { CustomerDetailsForm } from '../../types/CustomerDetailsForm'
import { Company } from '../../types/Company'
import { defaultCountryCode, defaultCountryName } from '../../consts'
import PannelWithDefaultButtons from '../../components/common/button/PannelWithDefaultButtons'
import { PaymentJourneyInfo } from '../../types/PaymentJourneyInfo'
import { getDefaultCountryValue, getFilteredByCurrencyCountries } from './FilterCountryByCurrency'

const maxLength = {
  value: 150,
  message: 'Maximum 150 characters'
}

export type LimitedCompanyDetailsProps = {
  data: PaymentJourneyInfo
  onSaveUserMutationSuccess?: (data: PaymentJourneyInfo) => void
}

const LimitedCompanyDetails = ({ data, onSaveUserMutationSuccess }: LimitedCompanyDetailsProps) => {
  const orderInfo = data.orderDetails
  const shouldCollectCompanyDetails = data.paymentJourney.shouldCollectCompanyDetails as boolean
  const availableCountries = data.paymentJourney.availableCountries!
  const orderCurrency = data.paymentDetails.payment.currency
  const { user, company } = orderInfo
  const isCompanyNameAndNumberPresent = !!(company?.companyName && company?.companyRegistrationNumber)
  const filteredByCurrencyCountries = getFilteredByCurrencyCountries(availableCountries, orderCurrency)
  const defaultCountryValue = getDefaultCountryValue(filteredByCurrencyCountries)
  const selectedCountryName = company?.countryOfRegistration
    ? availableCountries.find((c) => c.countryCode === company?.countryOfRegistration)?.countryName
    : defaultCountryName

  const form = useForm<CustomerDetailsForm>({
    mode: 'onChange',
    defaultValues: {
      user: {
        email: user?.email || '',
        fullName: user?.fullName || '',
        phoneNumber: user?.phoneNumber || ''
      },
      company: {
        companyName: isCompanyNameAndNumberPresent ? company?.companyName : '',
        companyRegistrationNumber: isCompanyNameAndNumberPresent ? company?.companyRegistrationNumber : '',
        type: company?.type || 'LimitedCompany',
        countryOfRegistration: company?.countryOfRegistration || defaultCountryValue,
        kriyaCompanyIdentifier: company?.kriyaCompanyIdentifier || ''
      }
    }
  })

  const {
    watch,
    register,
    handleSubmit,
    control,
    formState: { errors }
  } = form

  const requiredFields = watch(['user.fullName', 'user.email', 'user.phoneNumber'])

  const saveUserMutation = useUpdatePaymentJourneyInfo({
    onSuccess: onSaveUserMutationSuccess
  })

  const onSubmit = (formValues: CustomerDetailsForm) => {
    saveUserMutation.mutate({
      orderDetails: { ...orderInfo, user: formValues.user as User, company: formValues.company as Company },
      journey: {}
    })
  }
  return (
    <form onSubmit={handleSubmit(onSubmit)} id="contact-form">
      {shouldCollectCompanyDetails ? (
        <CompanyDetailsSearch
          form={form}
          defaultMode={defaultCountryValue === defaultCountryCode ? 'Search' : 'Input'}
          availableCountries={filteredByCurrencyCountries}
        ></CompanyDetailsSearch>
      ) : (
        <CompanyDetails companyDetails={company} countryName={selectedCountryName!} />
      )}
      <TextInput
        label="Your name"
        name="user.fullName"
        register={register}
        error={errors.user?.fullName}
        validation={{
          required: 'Please enter your full name',
          maxLength
        }}
      />

      <TextInput
        label="Your email"
        name="user.email"
        register={register}
        error={errors.user?.email}
        validation={{
          required: 'Please enter your email address',
          pattern: {
            value: emailPattern,
            message: 'Please enter a valid email address'
          },
          maxLength
        }}
      />

      <PhoneInput
        label="Your phone number"
        name="user.phoneNumber"
        control={control}
        error={errors.user?.phoneNumber}
        validation={{ isValidated: true, errorMessage: 'Please enter a valid phone number' }}
        defaultCountry={defaultCountryCode}
        availableCountries={availableCountries.map((c) => c.countryCode)}
        disableDropdown={false}
      />

      <PannelWithDefaultButtons
        marketplaceName={data.marketplace.name}
        redirectUrl={data.paymentJourney.paymentCancelledRedirectUrl}
        loading={saveUserMutation.isLoading}
        disabled={
          requiredFields.some((item) => item === undefined || item === '') ||
          Object.keys(errors).length > 0 ||
          saveUserMutation.isLoading
        }
      />
    </form>
  )
}

export default LimitedCompanyDetails
