import { apiClient } from '../api/apiClient'
import { useMutation, useQueryClient } from 'react-query'
import { useNavigate, useParams } from 'react-router-dom'
import { fetchOrderKey } from './useOrderInfo'
import { VerifySoleTraderResponse } from '../types/VerifySoleTraderResponse'
import { VerifySoleTraderResponseWithStatusCode } from '../types/VerifySoleTraderResponseWithStatusCode'

export const verifyBuyer = async (
  shortCode: string,
  companyIdentifier: string
): Promise<VerifySoleTraderResponseWithStatusCode> => {
  const response = await apiClient.post<VerifySoleTraderResponse>(`paymentjourney/${shortCode}/verifysoletrader`, {
    companyIdentifier: companyIdentifier
  })
  return { status: response.status, onboardingRedirectUrl: response.data.onboardingRedirectUrl }
}

export const useBuyerVerification = ({
  onSuccess,
  onError
}: {
  onSuccess?: (data: VerifySoleTraderResponseWithStatusCode) => void
  onError?: () => void
}) => {
  const { shortCode } = useParams()
  const navigate = useNavigate()
  const queryClient = useQueryClient()

  return useMutation(
    async (companyIdentifier: string): Promise<VerifySoleTraderResponseWithStatusCode> => {
      return await verifyBuyer(shortCode as string, companyIdentifier)
    },
    {
      onSuccess: async (data) => {
        await queryClient.invalidateQueries(fetchOrderKey)

        if (onSuccess) {
          onSuccess(data)
        }
      },
      onError: () => {
        if (onError) {
          onError()
        } else {
          navigate('/error')
        }
      }
    }
  )
}
