import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import React from 'react'
import theme from '../../assets/theme'
import styled from '@emotion/styled'
import { MarketplaceRepaymentsBankAccount } from '../../types/MarketplaceRepaymentsBankAccount'
import { H1 } from '../../components/common/heading'

const StyledBankAccountInstructionsBox = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing(1)};
  padding: ${theme.spacing(7, 9)};
`

const StyledBankAccountDetailRow = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: stretch;

  ${theme.breakpoints.down('sm')} {
    justify-content: center;
    flex-direction: column;
    gap: ${theme.spacing(7)};
  }
`

export type MarketplaceRepaymentsBankAccountProps = {
  marketplaceRepaymentsBankAccount?: MarketplaceRepaymentsBankAccount
}

const MarketplaceRepaymentsBankAccountDetails = (props: MarketplaceRepaymentsBankAccountProps) => {
  if (props.marketplaceRepaymentsBankAccount?.currencyCode?.toLowerCase() !== 'gbp') {
    return <></>
  }

  return (
    <>
      <H1 dataCy="payment-instruction-title">Payment instruction</H1>

      <Typography variant="body1" data-cy="payment-instruction-description">
        <span style={{ fontWeight: 'bold' }}>Ensure payments are made to the Kriya account provided below.</span>
        <br />
        Payments made to any other account, even if it belongs to the merchant, will be considered overdue and may
        trigger payment collection activities.
      </Typography>

      <Box
        sx={{ border: '1px solid #dedede', borderRadius: '8px', margin: theme.spacing(5, 0, 10, 0) }}
        data-cy="payment-instruction-bank-accounts"
      >
        <StyledBankAccountInstructionsBox>
          <StyledBankAccountDetailRow>
            <Box sx={{ width: '45%' }}>
              <Typography variant="body1">Account name</Typography>
            </Box>
            <Box sx={{ width: '55%' }}>
              <Typography variant="body1">
                <b>{props.marketplaceRepaymentsBankAccount.accountName}</b>
              </Typography>
            </Box>
          </StyledBankAccountDetailRow>
          <StyledBankAccountDetailRow>
            <Box sx={{ width: '45%' }}>
              <Typography variant="body1">Sort code</Typography>
            </Box>
            <Box sx={{ width: '55%' }}>
              <Typography variant="body1">
                <b>{props.marketplaceRepaymentsBankAccount.sortCode}</b>
              </Typography>
            </Box>
          </StyledBankAccountDetailRow>
          <StyledBankAccountDetailRow>
            <Box sx={{ width: '45%' }}>
              <Typography variant="body1">Account number</Typography>
            </Box>
            <Box sx={{ width: '55%' }}>
              <Typography variant="body1">
                <b>{props.marketplaceRepaymentsBankAccount.accountNumber}</b>
              </Typography>
            </Box>
          </StyledBankAccountDetailRow>
          <Box sx={{ marginTop: theme.spacing(7) }}>
            <Typography variant="body2" sx={{ color: '#3c3c3c' }}>
              You will receive an email reminding you of the payment due date and bank details a few days before the
              payment deadline.
            </Typography>
          </Box>
        </StyledBankAccountInstructionsBox>
      </Box>
    </>
  )
}

export default MarketplaceRepaymentsBankAccountDetails
