import React from 'react'
import { StyledTable, TR } from '../../components/common/table'
import { formatNumber } from '../../utils/amountFormatter'
import Box from '@mui/material/Box'
import styled from '@emotion/styled'
import theme from '../../assets/theme'
import { PaymentData } from '../../types/PaymentData'
import Typography from '@mui/material/Typography'

export type SpendingLimitProps = {
  paymentDetails: PaymentData
  availableLimit: number
}

type LimitTableDataProps = {
  alignText?: string
  padding?: string
  paddingMobile?: string
}

type RemainingLimitRowProps = {
  color?: string
}

const LimitTableData = styled.td<LimitTableDataProps>`
  text-align: ${(props) => (props.alignText ? props.alignText : 'left')};
  padding: ${(props) => (props.padding ? props.padding : 0)};
  font-size: ${theme.typography.body2.fontSize};
  line-height: ${theme.typography.body2.lineHeight};
  font-weight: ${theme.typography.body2.fontWeight};
  font-family: ${theme.typography.body2.fontFamily};
  ${theme.breakpoints.down('sm')} {
    padding: ${(props) => props.paddingMobile ?? props.paddingMobile ?? 0};
  }
`

export const LimitTable = styled(StyledTable)`
  width: auto;
  ${() => theme.breakpoints.down('mobile')} {
    min-width: 100%;
  }
`

const RemainingLimitRow = styled(TR)<RemainingLimitRowProps>`
  color: ${(props) => (props.color ? props.color : 'inherit')};
`

const SpendingLimitTable = (props: SpendingLimitProps) => {
  const { amount, currency } = props.paymentDetails
  const remainingLimit = props.availableLimit - amount

  return (
    <>
      <Typography variant="subtitle2" sx={{ marginTop: theme.spacing(8), marginBottom: theme.spacing(5) }}>
        Spending limit summary
      </Typography>
      <Box sx={{ maxWidth: 400 }}>
        <LimitTable id="limit-table">
          <tbody>
            <TR>
              <LimitTableData>Available spending limit</LimitTableData>
              <LimitTableData alignText="right">{formatNumber(props.availableLimit, currency)}</LimitTableData>
            </TR>
            <TR borderTop="none">
              <LimitTableData padding={theme.spacing(0, 0, 4, 0)}>This order</LimitTableData>
              <LimitTableData alignText="right" padding={theme.spacing(0, 0, 4, 0)}>
                <span style={{ whiteSpace: 'nowrap' }}>-{formatNumber(amount, currency)}</span>
              </LimitTableData>
            </TR>
            <RemainingLimitRow color={remainingLimit < 0 ? theme.palette.common.uiError : 'inherit'}>
              <LimitTableData padding={theme.spacing(3, 7, 0, 0)} paddingMobile={theme.spacing(3, 11, 0, 0)}>
                Available spending limit after this order
              </LimitTableData>
              <LimitTableData alignText="right" padding={theme.spacing(3, 0, 0)}>
                {formatNumber(remainingLimit, currency)}
              </LimitTableData>
            </RemainingLimitRow>
          </tbody>
        </LimitTable>
      </Box>
    </>
  )
}

export default SpendingLimitTable
