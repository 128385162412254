import React, { ReactNode } from 'react'
import Backdrop from '@mui/material/Backdrop'
import theme from '../../../assets/theme'
import Box from '@mui/material/Box'
import Spinner from './Spinner'
import TrustPilotBox from './TrustPilot'

export interface LoadingProps {
  header?: string
  bodyMessage?: string
  body?: ReactNode
  addendumMessage?: string
  addendum?: ReactNode
  isTrustPilotWidgetDisplayed?: boolean
}

const Loading = ({
  header = 'Getting everything ready...',
  isTrustPilotWidgetDisplayed = false,
  body = undefined,
  bodyMessage,
  addendum = undefined,
  addendumMessage = `Please don't close the window.`
}: LoadingProps) => {
  return (
    <Backdrop
      sx={{
        zIndex: (theme) => theme.zIndex.drawer + 1,
        background: theme.palette.common.white
      }}
      open={true}
    >
      <Box
        sx={{
          position: 'absolute',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          textAlign: 'center',
          paddingLeft: {
            xs: theme.spacing(7),
            mobile: theme.spacing(11)
          },
          paddingRight: {
            xs: theme.spacing(7),
            mobile: theme.spacing(11)
          }
        }}
      >
        <Box id="loaderHeader" sx={{ typography: 'loadingPageHeader', paddingBottom: theme.spacing(10) }}>
          {header}
        </Box>
        <Box
          id="loaderBody"
          sx={{
            typography: 'body1',
            color: theme.palette.common.textGrey600,
            paddingTop: theme.spacing(2),
            paddingBottom: {
              xs: theme.spacing(4),
              mobile: theme.spacing(4)
            }
          }}
        >
          {bodyMessage}
          {body}
        </Box>
        <Box>
          <Spinner></Spinner>
        </Box>
        <Box
          id="loaderAddendum"
          sx={{
            typography: 'body1',
            color: theme.palette.common.textGrey600,
            paddingTop: theme.spacing(8),
            paddingBottom: {
              xs: theme.spacing(14),
              mobile: theme.spacing(12)
            }
          }}
        >
          {addendumMessage}
          {addendum}
        </Box>
        {isTrustPilotWidgetDisplayed && <TrustPilotBox />}
      </Box>
    </Backdrop>
  )
}

export default Loading
