import React from 'react'
import Box from '@mui/material/Box'
import theme from '../../assets/theme'

const CreditCheck = () => {
  return (
    <>
      <Box sx={{ typography: 'body2', marginTop: theme.spacing(10) }}>
        By clicking "Continue", you agree to Kriya using your details to assess your eligibility for credit which may
        include soft checks at credit bureaus.
      </Box>
    </>
  )
}

export default CreditCheck
