import React from 'react'
import { formatNumber } from '../../utils/amountFormatter'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import styled from '@emotion/styled'
import theme from '../../assets/theme'

const StyledSpendingLimitBox = styled(Box)`
  display: flex;
  flex-direction: column;
  background: rgba(25, 135, 84, 0.08);
  border: solid 2px #198754;
  color: #198754;
  border-radius: 6px;
  gap: 7px;
  padding: 30px 38px;
  ${theme.breakpoints.down('sm')} {
    gap: ${theme.spacing(7)};
  }
`

const StyledSpendingLimitsRow = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: stretch;

  ${theme.breakpoints.down('sm')} {
    justify-content: center;
    flex-direction: column;
    gap: ${theme.spacing(7)};
  }
`

export type BuyerSpendingLimitProps = {
  totalSpendingLimit: number
  availableLimit: number
  status: string
  currency: string
}

const BuyerSpendingLimit = (props: BuyerSpendingLimitProps) => {
  return (
    <StyledSpendingLimitBox data-cy="spending-limit-box">
      <Box>
        <Typography variant="body1">
          Your organisation is <b>eligible</b> to Buy Now Pay Later.
        </Typography>
      </Box>
      <StyledSpendingLimitsRow>
        <Box sx={{ flexGrow: '1' }}>
          <Box>
            <Typography variant="body1">Total spending limit</Typography>
          </Box>
          <Box>
            <Typography variant="h1" data-cy="total-limit-value">
              {formatNumber(props.totalSpendingLimit, props.currency)}
            </Typography>
          </Box>
        </Box>
        <Box sx={{ flexGrow: '1' }}>
          <Box>
            <Typography variant="body1">Available spending limit</Typography>
          </Box>
          <Box>
            <Typography variant="h1" data-cy="available-limit-value">
              {formatNumber(props.availableLimit, props.currency)}
            </Typography>
          </Box>
        </Box>
      </StyledSpendingLimitsRow>
    </StyledSpendingLimitBox>
  )
}

export default BuyerSpendingLimit
