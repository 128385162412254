import { apiClient } from '../api/apiClient'
import { useMutation, useQueryClient } from 'react-query'
import { fetchOrderKey } from './useOrderInfo'
import { useNavigate, useParams } from 'react-router-dom'
import { UpdatePaymentJourney } from '../types/UpdatePaymentJourney'
import { PaymentJourneyInfo } from '../types/PaymentJourneyInfo'
import { AxiosError } from 'axios'
import { GetPrePopulationId, SavePrePopulationId } from '../utils/userInfoPopulation'

export const updatePaymentJourneyInfo = async (
  paymentJourneyInfo: UpdatePaymentJourney,
  shortCode: string
): Promise<PaymentJourneyInfo> => {
  const response = await apiClient.put<PaymentJourneyInfo>(`paymentjourney/${shortCode}`, paymentJourneyInfo)
  return response.data
}

export const useUpdatePaymentJourneyInfo = ({
  onSuccess,
  onError
}: {
  onSuccess?: (data: PaymentJourneyInfo) => void
  onError?: () => void
}) => {
  const { shortCode } = useParams()
  const navigate = useNavigate()
  const queryClient = useQueryClient()

  const savePaymentJourneyMutation = useMutation(
    async (restUserProperties: UpdatePaymentJourney): Promise<PaymentJourneyInfo> => {
      restUserProperties.journey.prePopulationId = GetPrePopulationId()
      const data = await updatePaymentJourneyInfo(restUserProperties, shortCode as string)
      return data
    },
    {
      onSuccess: async (data) => {
        SavePrePopulationId(data.paymentJourney.prePopulationId)

        await queryClient.invalidateQueries(fetchOrderKey)

        if (onSuccess) {
          onSuccess(data)
        }
      },
      onError: (error: AxiosError) => {
        if (onError) {
          onError()
        } else {
          navigate('/error')
        }
      }
    }
  )

  return savePaymentJourneyMutation
}
