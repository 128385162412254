const localization = navigator.language

export const formatNumber = (number: number, currency: string) => {
  if (!currency) {
    currency = 'GBP'
  }

  const formatter = new Intl.NumberFormat(localization, {
    style: 'currency',
    currency: currency.toUpperCase(),
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  })

  return formatter.format(number)
}

export const formatPercent = (number: number) => {
  const formatter = new Intl.NumberFormat(localization, {
    style: 'percent',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  })

  return formatter.format(number)
}
