import React from 'react'
import Typography from '@mui/material/Typography'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import theme from '../../assets/theme'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import Button from '@mui/material/Button'
import styled from '@emotion/styled'
import useMediaQuery from '@mui/material/useMediaQuery'

export interface UnrecognizedOrderDialogProps {
  onConfirm: () => void
  disabled: boolean
}

const StyledActions = styled(DialogActions)`
  ${theme.breakpoints.down('sm')} {
    display: block;
  }
`

const UnrecognizedOrderDialog = (props: UnrecognizedOrderDialogProps) => {
  const [open, setOpen] = React.useState(false)
  const isMobile = useMediaQuery(theme.breakpoints.down('mobile'))

  const handleDialogOpen = () => {
    setOpen(true)
  }

  const handleDialogClose = () => {
    setOpen(false)
  }

  const handleRejection = () => {
    props.onConfirm()
  }

  let buttons = [
    <Button
      variant="outlined"
      key="button-close-modal"
      sx={{ height: '36px', width: '100%', marginTop: isMobile ? '16px' : 0 }}
      onClick={handleDialogClose}
      id="unrecognized-order-btn-close"
    >
      <Typography variant="body2"> Return to previous screen </Typography>
    </Button>,
    <Button
      fullWidth={true}
      variant="contained"
      key="button-reject-order"
      sx={{ minWidth: '140px', height: '36px', width: '100%', marginTop: isMobile ? '16px' : 0 }}
      onClick={handleRejection}
      id="unrecognized-order-btn-confirm"
    >
      <Typography variant="body2">Reject order</Typography>
    </Button>
  ]
  if (isMobile) {
    buttons = buttons.reverse()
  }

  return (
    <>
      <Button variant="text" onClick={handleDialogOpen} disabled={props.disabled} id="unrecognized-order-btn">
        <Typography variant="body1" sx={{ fontWeight: { mobile: 600 } }}>
          I don’t recognise this order
        </Typography>
      </Button>
      <Dialog
        onClose={handleDialogClose}
        open={open}
        role="dialog"
        aria-labelledby="unrecognized-order-dialog-title"
        aria-describedby="unrecognized-order-content"
      >
        <DialogTitle sx={{ padding: theme.spacing(0, 0, 2, 0) }} id="unrecognized-order-dialog-title">
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography variant="dialogTitle"> Order unrecognised? </Typography>
            <div>
              <IconButton onClick={handleDialogClose}>
                <CloseIcon fontSize="small" />
              </IconButton>
            </div>
          </div>
        </DialogTitle>
        <DialogContent id="unrecognized-order-content">
          <Typography
            variant="body1"
            color={theme.palette.common.textGrey700}
            sx={{
              pt: {
                xs: theme.spacing(6),
                mobile: theme.spacing(5)
              }
            }}
          >
            If you do not recognise this order, please confirm your decision to reject it.
          </Typography>
        </DialogContent>
        <StyledActions disableSpacing={isMobile}>{buttons.map((r) => r)}</StyledActions>
      </Dialog>
    </>
  )
}

export default UnrecognizedOrderDialog
