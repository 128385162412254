import React from 'react'
import CircularProgress from '@mui/material/CircularProgress'
import Box from '@mui/material/Box'
import theme from '../../../assets/theme'

const Spinner = () => {
  return (
    <Box sx={{ position: 'relative' }} id="spinner">
      <CircularProgress
        variant="determinate"
        sx={{ color: theme.palette.common.backgroundGrey100 }}
        value={100}
        size={64}
      />
      <CircularProgress
        variant="indeterminate"
        disableShrink
        size={64}
        color="primary"
        sx={{ position: 'absolute', left: 0, animationDuration: '600ms' }}
      />
    </Box>
  )
}

export default Spinner
