import styled from '@emotion/styled'
import theme from '../../../assets/theme'
import Button from '@mui/material/Button'
import { css } from '@emotion/react'
import LoadingButton from '@mui/lab/LoadingButton'

export const ButtonCss = css`
  padding: ${theme.spacing(7, 6)};
  font-size: 16px;
  line-height: 28px;
  border-radius: 100px;
  ${theme.breakpoints.down('mobile')} {
    width: 100%;
    font-size: 14px;
    line-height: 20px;
    margin-bottom: ${theme.spacing(5)};
  }
`
// Idea behind button namings https://kriya-finance.atlassian.net/wiki/spaces/PROD/pages/3056631846/Button
export const StyledStandardButton = styled(Button)`
  ${ButtonCss}
`
export const StyledOutlinedButton = styled(StyledStandardButton)`
  min-width: 100px;
`

export const StyledLoadingButton = styled(LoadingButton)`
  ${ButtonCss}
`
