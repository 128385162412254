import { BusinessType } from '../types/enums/BusinessType'

const availableStorage = typeof window.localStorage !== undefined

export const userInfoKey = 'mpaydata'
export const prePopulationIdHeaderName = 'X-Kriya-PrePopulationId'

export const RetrieveUserData = (key: string): string | null => {
  return availableStorage ? window.localStorage.getItem(key) : null
}

export const SavePrePopulationId = (prePopulationId?: string) => {
  if (availableStorage && prePopulationId !== undefined) window.localStorage.setItem(userInfoKey, prePopulationId)
}

export const GetPrePopulationId = (): string | undefined => {
  const prePopulationId = RetrieveUserData(userInfoKey)
  if (prePopulationId != null) {
    return prePopulationId
  }
  return undefined
}

export const SaveUserCompanyType = (key: string, type?: BusinessType) => {
  if (availableStorage && type !== undefined) window.localStorage.setItem(key, type)
}

export const DeleteLocalStorageItem = (key: string) => {
  if (availableStorage && key !== undefined) window.localStorage.removeItem(key)
}
