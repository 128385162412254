import { PaymentJourneyStatus } from '../../types/enums/PaymentJourneyStatus'

const terminalOrderStatusRedirectionMap: {
  [key in PaymentJourneyStatus]?: string
} = {
  [PaymentJourneyStatus.Completed]: 'invalid',
  [PaymentJourneyStatus.Expired]: 'invalid',
  [PaymentJourneyStatus.Ineligible]: 'ineligible',
  [PaymentJourneyStatus.InsufficientFunds]: 'insufficient-funds',
  [PaymentJourneyStatus.InsufficientFundsOther]: 'insufficient-funds-other',
  [PaymentJourneyStatus.UnverifiedSoleTrader]: 'unverified-sole-trader',
  [PaymentJourneyStatus.InReview]: 'in-review',
  [PaymentJourneyStatus.KycCheckIsFailed]: 'kyc-check-is-failed',
  [PaymentJourneyStatus.InsufficientFundsKycCheckIsRequired]: 'insufficient-funds-kyc-check-required',
  [PaymentJourneyStatus.MfaSessionExpired]: 'mfa-expired',
  [PaymentJourneyStatus.MfaSessionRejected]: 'mfa-failed',
  [PaymentJourneyStatus.MfaNotSupported]: 'mfa-not-supported',
  [PaymentJourneyStatus.InsufficientFundsOnboardingChecksAreRequired]:
    'insufficient-funds-onboarding-checks-are-required',
  [PaymentJourneyStatus.OnboardingChecksAreFailed]: 'onboarding-checks-failed'
}

const activeOrderStatusRedirectionMap: {
  [key in PaymentJourneyStatus]?: string
} = {
  [PaymentJourneyStatus.Eligible]: 'payment',
  [PaymentJourneyStatus.MfaSessionInProgress]: 'mfa-polling',
  [PaymentJourneyStatus.MfaSessionApproved]: 'mfa-approved'
}

export const GetTerminalOrderStatusRedirection = (journeyStatus?: PaymentJourneyStatus) => {
  if (journeyStatus) {
    return terminalOrderStatusRedirectionMap[journeyStatus]
  }
  return undefined
}

export const GetActiveOrderStatusRedirection = (journeyStatus?: PaymentJourneyStatus) => {
  const defaultRedirect = 'customer-details'
  if (journeyStatus) {
    return activeOrderStatusRedirectionMap[journeyStatus] || defaultRedirect
  }
  return undefined
}
