import React, { ReactElement } from 'react'
import MuiAccordion from '@mui/material/Accordion'
import MuiAccordionSummary from '@mui/material/AccordionSummary'
import MuiAccordionDetails from '@mui/material/AccordionDetails'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import Typography from '@mui/material/Typography'

interface Props {
  items: {
    title: string
    content?: ReactElement
  }[]
}

const AccordionPanel: React.FC<Props> = (props: Props) => {
  const [expanded, setExpanded] = React.useState<string[]>([])

  const handleChange = (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
    setExpanded(newExpanded ? expanded.concat(panel) : expanded.filter((r) => r !== panel))
  }
  return (
    <>
      {props.items.map((accordion, index) => {
        const name = `panel${index}`

        return (
          <MuiAccordion key={index} expanded={expanded.indexOf(name) > -1} onChange={handleChange(name)}>
            <MuiAccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls={`${name}-content`}
              id={`${name}-header`}
            >
              <Typography variant="caption2">{accordion.title} </Typography>
            </MuiAccordionSummary>
            <MuiAccordionDetails>{accordion.content}</MuiAccordionDetails>
          </MuiAccordion>
        )
      })}
    </>
  )
}

export default AccordionPanel
