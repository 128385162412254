import React, { useState } from 'react'
import OrderErrors, { ErrorMessage } from '../error/OrderErrors'
import useFetchCacheData from '../../custom-hooks/useFetchCacheData'
import { useNavigate, useParams } from 'react-router-dom'
import { StyledLoadingButton, StyledStandardButton } from '../../components/common/button/Buttons'
import styled from '@emotion/styled'
import Box from '@mui/material/Box'
import theme from '../../assets/theme'
import Typography from '@mui/material/Typography'
import { useResendMfaSession } from '../../custom-hooks/useResendMfaSession'
import { usePaymentJourneyStatusPolling } from '../../custom-hooks/usePaymentJourneyStatusPoling'
import { PaymentJourneyStatus } from '../../types/enums/PaymentJourneyStatus'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'

const StyledButtonContainer = styled(Box)`
  margin-top: ${theme.spacing(11)};

  ${theme.breakpoints.down('sm')} {
    margin-top: ${theme.spacing(10)};
  }
`

const MfaExpired = () => {
  const paymentJourneyData = useFetchCacheData()
  const navigate = useNavigate()
  const { shortCode } = useParams()
  const [resendTriggered, setResendTriggered] = useState(false)

  const { mutate: resendMfaSessionMutation, isLoading: resendInProgress } = useResendMfaSession()

  usePaymentJourneyStatusPolling({
    onError: () => {
      navigate('/error')
    },
    onComplete: (data) => {
      setResendTriggered(false)
      if (data.status === PaymentJourneyStatus.MfaSessionInProgress && shortCode != null) {
        navigate(`/${shortCode}/mfa-pooling`)
      }
    },
    isEnabled: resendTriggered,
    ignorableStatus: PaymentJourneyStatus.MfaSessionExpired
  })

  if (paymentJourneyData === undefined || paymentJourneyData.session?.token === null) {
    navigate('/error', {
      replace: true
    })
    return null
  }

  const errorHeader = `No response yet`

  const onReturnToMerchantLinkClicked = () => {
    window.location.replace(paymentJourneyData?.paymentJourney.paymentCancelledRedirectUrl as string)
  }

  const onResendSmsClicked = () => {
    resendMfaSessionMutation(
      {
        marketplaceOrderId: paymentJourneyData.orderDetails.marketPlaceOrderId,
        sessionToken: paymentJourneyData.session.token
      },
      {
        onSuccess: () => {
          setResendTriggered(true)
        }
      }
    )
  }

  return (
    <OrderErrors errorHeader={errorHeader} shouldHideReturnButton={true}>
      <ErrorMessage>
        We haven't received a response since the SMS was sent. Feel free to send the SMS again to restart the
        verification process.
      </ErrorMessage>
      <StyledButtonContainer>
        <StyledLoadingButton
          variant="contained"
          onClick={onResendSmsClicked}
          id="resend-sms-button"
          disabled={resendInProgress || resendTriggered}
          loading={resendInProgress || resendTriggered}
          sx={{
            minWidth: '170px'
          }}
          loadingPosition="end"
          endIcon={<ChevronRightIcon />}
        >
          Resend SMS
        </StyledLoadingButton>
        <StyledStandardButton variant="text" onClick={onReturnToMerchantLinkClicked} id="return-to-merchant-btn">
          <Typography variant="body1" sx={{ fontWeight: { mobile: 600 } }}>
            Return to {paymentJourneyData.marketplace.name}
          </Typography>
        </StyledStandardButton>
      </StyledButtonContainer>
    </OrderErrors>
  )
}

export default MfaExpired
