import React from 'react'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import theme from '../../../assets/theme'

interface TableProps {
  id?: string
  width?: string
  children: React.ReactElement
}

type TableRowProps = {
  borderTop?: string
}

export const StyledTable = styled.table<TableProps>`
  border-collapse: collapse;
  table-layout: fixed;
  width: ${(props) => (props.width ? props.width : '100%')};

  ${() => css`
    color: ${theme.palette.text.primary};
    font-size: ${theme.typography.body1.fontSize};
    line-height: ${theme.typography.body1.lineHeight};
  `};

  ${() => theme.breakpoints.down('mobile')} {
    font-size: ${() => theme.typography.body2.fontSize};
    line-height: ${() => theme.typography.body2.lineHeight};
  }
`

export const Table: React.FC<TableProps> = ({ children, id, width }) => {
  return (
    <StyledTable theme={theme} id={id} width={width}>
      {children}
    </StyledTable>
  )
}

export const THead = styled.thead`
  // custom css goes here
`

export const TFoot = styled.tfoot`
  // custom css goes here
`

export const TBody = styled.tbody`
  // custom css goes here
`

export const TR = styled.tr<TableRowProps>`
  text-align: left;

  &:not(:first-of-type) {
    border-top: ${(props) => (props.borderTop ? props.borderTop : `1px solid ${theme.palette.common.backgroundGrey}`)};
  }
`

export const TH = styled.th`
  // custom css goes here
`

const StyledTD = styled.td`
  padding-top: ${theme.spacing(2)};
  padding-bottom: ${theme.spacing(2)};
  padding-right: ${theme.spacing(6)};

  &:first-of-type {
    vertical-align: top;
  }

  ${() => theme.breakpoints.down('mobile')} {
    padding-bottom: ${theme.spacing(3)};
    padding-right: ${theme.spacing(5)};
  }
`

export const TD = ({ children }: { children: React.ReactNode }) => {
  return <StyledTD theme={theme}>{children}</StyledTD>
}
