import { useQuery } from 'react-query'
import { apiClient } from '../api/apiClient'
import { retry } from '../api/apiBaseUrl'
import { MfaSession } from '../types/MfaSession'

export const mfaSessionKey = 'mfa-session'

export const fetchOrderDetails = async (
  marketplaceOrderId: string,
  sessionToken: string,
  recipientId: string | undefined = undefined
): Promise<MfaSession> => {
  let url = `/paymentjourney/${marketplaceOrderId}/session/${sessionToken}`
  if (recipientId) {
    url += `?recipientId=${recipientId}`
  }
  const response = await apiClient.get<MfaSession>(url)
  return response.data
}

export const useMfaSession = (
  request: {
    marketplaceOrderId: string
    sessionToken: string
    recipientId?: string
  } | null
) => {
  const enabled = request !== null
  const queryKey = request ? [mfaSessionKey, request.marketplaceOrderId, request.sessionToken] : [mfaSessionKey]

  const { data, isLoading, error, isSuccess } = useQuery<MfaSession, Error>(
    queryKey,
    () => fetchOrderDetails(request!.marketplaceOrderId, request!.sessionToken, request?.recipientId),
    {
      retry,
      refetchOnWindowFocus: false,
      enabled
    }
  )

  return { data, isLoading, error, isSuccess }
}
