import { apiClient } from '../api/apiClient'
import { useMutation } from 'react-query'
import { useNavigate, useParams } from 'react-router-dom'
import { AxiosError } from 'axios'
import { PlaceOrderResponse } from '../types/PlaceOrderResponse'

export const completeOrder = async (shortCode: string): Promise<PlaceOrderResponse> => {
  const response = await apiClient.post<PlaceOrderResponse>(`paymentjourney/${shortCode}/complete`)
  return response.data
}

export const useCompleteOrder = ({
  onSuccess,
  onError
}: {
  onSuccess?: (data: PlaceOrderResponse) => void
  onError?: () => void
}) => {
  const { shortCode } = useParams()
  const navigate = useNavigate()

  const completeOrderMutation = useMutation(
    async (): Promise<PlaceOrderResponse> => {
      const data = await completeOrder(shortCode as string)
      return data
    },
    {
      onSuccess: (data) => {
        if (onSuccess) {
          onSuccess(data)
        }
      },
      onError: (error: AxiosError) => {
        if (onError) {
          onError()
        } else {
          navigate('/error')
        }
      }
    }
  )

  return completeOrderMutation
}
