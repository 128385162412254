import React from 'react'
import theme from '../../assets/theme'
import Box from '@mui/material/Box'
import { UseFormReturn } from 'react-hook-form'
import TextInput from '../input/TextInput'
import { CustomerDetailsForm } from '../../types/CustomerDetailsForm'
import FormSelect from '../input/FormSelect'
import { SearchModeComponent } from './SearchModeComponent'

const autoCompleteCountries = ['FR', 'IE']

const captionBoxStyling = {
  p: {
    mobile: theme.spacing(7, 0, 11),
    xs: theme.spacing(7, 0, 10)
  }
}

type InputModeProps = {
  form: UseFormReturn<CustomerDetailsForm, any>
  availableCountries: { value: string; label: string }[]
}
export const InputModeComponent = ({ form, availableCountries }: InputModeProps) => {
  const {
    watch,
    register,
    control,
    formState: { errors }
  } = form

  const countrySelection = watch(['company.countryOfRegistration'])

  return (
    <Box sx={captionBoxStyling}>
      <FormSelect
        label="Country of incorporation"
        name="company.countryOfRegistration"
        control={control}
        error={errors.company?.countryOfRegistration}
        validation={{
          required: 'Please select country of incorporation'
        }}
        options={availableCountries}
      ></FormSelect>

      {autoCompleteCountries.find((x) => countrySelection?.includes(x)) ? (
        <SearchModeComponent form={form} toggleMode={() => 'Search'} />
      ) : (
        <>
          <TextInput
            label="Company name"
            name="company.companyName"
            register={register}
            error={errors.company?.companyName}
            validation={{
              required: 'Enter name or organisation number',
              maxLength: {
                value: 160,
                message: 'Maximum 160 characters'
              },
              pattern: {
                value: /^[A-Za-z0-9&@£$€¥#.,:;\-\s]{0,160}$/,
                message: 'Company name can have letters, numbers, spaces and the following symbols: .,:;-&@£$€¥#'
              }
            }}
          />
          <TextInput
            label="Company registration number"
            name="company.companyRegistrationNumber"
            register={register}
            error={errors.company?.companyRegistrationNumber}
            validation={{
              required: 'Please enter company registration number'
            }}
          />
        </>
      )}
    </Box>
  )
}
