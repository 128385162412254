import React, { useEffect } from 'react'
import { telemetryService, trackError } from './utils/metrics/appInsights/AppInsights'
import { withAITracking } from '@microsoft/applicationinsights-react-js'
import instrumentationKey from './utils/metrics/appInsights/appInsightsKeyMapping'
import SegmentWrapper from './utils/metrics/segmentWindow'
import { StyledLayoutContainer } from './components/layout/Container'
import NavigationBar from './components/layout/NavigationBar'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import Order from './pages/Order'
import NotFound from './pages/error/PageNotFound'
import InternalServerError from './pages/error/InternalServerError'
import { ThemeProvider } from '@mui/material/styles'
import theme from './assets/theme'
import { QueryClientProvider, QueryClient } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import OrderNotFound from './pages/error/OrderNotFound'
import Polling from './pages/shopifyPayment/Polling'
import MfaApproval from './pages/mfa/MfaApproval'

const queryClient = new QueryClient()

const AppWrapper = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <App />
    </QueryClientProvider>
  )
}

function App() {
  useEffect(() => {
    telemetryService.initialize(instrumentationKey(window.location.href))
    SegmentWrapper.page('PaymentJourney')
  }, [])

  useEffect(() => {
    window.addEventListener('error', trackError)
    return () => {
      window.removeEventListener('error', trackError)
    }
  }, [])

  return (
    <>
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <NavigationBar>
            <StyledLayoutContainer>
              <Routes>
                <Route path="/error" element={<InternalServerError />} />
                <Route path="/not-found" element={<OrderNotFound />} />
                <Route path="/:shortCode/polling" element={<Polling />} />
                <Route path="/session/:merchantOrderId/:sessionToken/:recipientId" element={<MfaApproval />} />
                <Route path="/:shortCode/*" element={<Order />} />
                <Route path="*" element={<NotFound />} />
              </Routes>
            </StyledLayoutContainer>
          </NavigationBar>
        </BrowserRouter>
      </ThemeProvider>
      <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
    </>
  )
}

const reactPlugin = telemetryService && telemetryService.reactPlugin

export default withAITracking(reactPlugin, AppWrapper)
