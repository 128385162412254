import React, { Dispatch, SetStateAction } from 'react'
import Box from '@mui/material/Box'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup/RadioGroup'
import Typography from '@mui/material/Typography'
import FormControlLabel from '@mui/material/FormControlLabel'
import { SoleTraderIdentifier } from '../../types/enums/SoleTraderIdentifier'
import theme from '../../assets/theme'

export type SoleTraderIdentifierSelectionProps = {
  soleTraderIdentifier: SoleTraderIdentifier
  onSoleTraderIdentifierChanged: Dispatch<SetStateAction<SoleTraderIdentifier>>
}

const CompanyStructure = ({
  soleTraderIdentifier,
  onSoleTraderIdentifierChanged
}: SoleTraderIdentifierSelectionProps) => {
  const onChange = (_: React.ChangeEvent<HTMLInputElement>, value: string) => {
    onSoleTraderIdentifierChanged(value as SoleTraderIdentifier)
  }

  return (
    <>
      <Typography data-cy="sole-trader-identifier-header" variant="caption">
        How do you want to identify yourself?
      </Typography>
      <Typography data-cy="sole-trader-identifier-description" variant="body2" sx={{ marginTop: theme.spacing(4) }}>
        If you have used Kriya PayLater before, please use the same email or number you registered before
      </Typography>
      <Box>
        <RadioGroup
          aria-labelledby="sole-trader-identification-radio-buttons-group"
          name="sole-trader-identification-radio-buttons-group"
          onChange={onChange}
          value={soleTraderIdentifier}
        >
          <FormControlLabel value={SoleTraderIdentifier.Email} control={<Radio id="email-radio" />} label="Email" />
          <FormControlLabel
            value={SoleTraderIdentifier.PhoneNumber}
            control={<Radio id="phoneNumber-radio" />}
            label="Mobile number"
          />
        </RadioGroup>
      </Box>
    </>
  )
}

export default CompanyStructure
