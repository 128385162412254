import React, { useState } from 'react'
import { FieldValues, FieldError, Control, Controller } from 'react-hook-form'
import { isValidPhoneNumber } from 'libphonenumber-js'
import theme from '../../../assets/theme'
import ErrorText from '../FormErrors'
import MuiPhoneNumber from 'material-ui-phone-number'
import FormControl from '@mui/material/FormControl'
import FormLabel from '@mui/material/FormLabel'
import Typography from '@mui/material/Typography'
import './PhoneInput.css'

interface Props {
  control: Control<FieldValues, any>
  name: string
  label: string
  optional?: boolean
  validation?: { isValidated: boolean; errorMessage: string }
  error?: FieldError
  defaultCountry?: string
  availableCountries?: string[]
  disableDropdown: boolean
}

const PhoneInput: React.FC<Props> = ({
  control,
  name,
  label,
  error,
  optional,
  validation,
  defaultCountry,
  availableCountries,
  disableDropdown
}) => {
  const [inputClass, setInputClass] = useState('chevronDown')
  const defaultCountryValue = defaultCountry ? defaultCountry.toLocaleLowerCase() : 'gb'

  const countriesForDisplay = availableCountries ? availableCountries.map((c) => c.toLocaleLowerCase()) : ['gb']

  const getFormattedPhoneNumber = (value: string) => {
    let valueStr = value as unknown as string
    if (valueStr.startsWith('0') || valueStr.startsWith('+0')) {
      // local phone number starts with 0 not only for UK
      // however the assumption is that all local phones is UK's
      valueStr = `+44${valueStr.substring(valueStr.indexOf('0') + 1)}`
    }
    return valueStr
  }

  return (
    <Controller
      control={control}
      name={name}
      rules={
        validation?.isValidated
          ? {
              validate: (value: string) => {
                // If phone number is optional do not validate it until at least 4 letters entered
                if (optional && value.length < 4) {
                  return true
                }

                const valueStr = getFormattedPhoneNumber(value as unknown as string)

                return isValidPhoneNumber(valueStr) || validation.errorMessage || 'Please enter a valid phone number'
              }
            }
          : undefined
      }
      defaultValue=""
      render={({ field: { onChange, onBlur, value } }) => {
        const valueStr = getFormattedPhoneNumber(value as unknown as string)

        const handleChevronSwitchOnClick = (event: any) => {
          const eventObject = event as unknown as Event
          const node = eventObject.target as unknown as HTMLElement
          if (node.localName === 'input') return

          const dropDownMenu = document.getElementById('country-menu')
          dropDownMenu != null ? setInputClass('chevronDown') : setInputClass('chevronUp')
          if (!dropDownMenu) {
            ;(document.querySelector('.MuiPhoneNumber-flagButton') as HTMLDivElement).click()
          }
        }

        return (
          <FormControl>
            <FormLabel htmlFor={name}>
              {optional ? (
                <>
                  {label}{' '}
                  <Typography variant="inputLabel" sx={{ color: theme.palette.common.textGrey600 }}>
                    (optional)
                  </Typography>{' '}
                </>
              ) : (
                label
              )}
            </FormLabel>
            <MuiPhoneNumber
              id={name}
              variant="outlined"
              onChange={(value) => {
                onChange(getFormattedPhoneNumber(value as string))
              }}
              onClick={handleChevronSwitchOnClick}
              onBlur={onBlur}
              value={valueStr}
              data-cy={name}
              defaultCountry={defaultCountryValue}
              onlyCountries={countriesForDisplay}
              error={error ? true : false}
              dropdownClass="phoneNumber"
              inputClass={inputClass}
              disableAreaCodes={true}
              disableDropdown={disableDropdown}
              autoFormat={false}
              aria-invalid={error ? 'true' : 'false'}
              aria-describedby={error && `${name}-error`}
            />
            {error?.message && <ErrorText id={name}>{error.message}</ErrorText>}
          </FormControl>
        )
      }}
    />
  )
}

export default PhoneInput
