import { AxiosError } from 'axios'
import { useParams } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { useQuery, useQueryClient } from 'react-query'
import { retry } from '../api/apiBaseUrl'
import { apiClient } from '../api/apiClient'
import { PaymentJourneyStatus } from '../types/enums/PaymentJourneyStatus'
import { PaymentJourneyStatusResponse } from '../types/PaymentJourneyStatusResponse'
import { fetchOrderKey } from './useOrderInfo'
import { mfaSessionKey } from './useMfaSession'

const fetchRedirectUrlsKey = 'fetch-mfa-session-status'

export const fetchPaymentJourneyStatus = async (shortCode: string): Promise<PaymentJourneyStatusResponse> => {
  const url = `/paymentjourney/${shortCode}/status`

  const response = await apiClient.get<PaymentJourneyStatusResponse>(url)
  return response.data
}

export const usePaymentJourneyStatusPolling = ({
  onError,
  onComplete,
  isEnabled = true,
  ignorableStatus = PaymentJourneyStatus.MfaSessionInProgress
}: {
  onError: (error?: AxiosError) => void
  onComplete?: (data: PaymentJourneyStatusResponse) => void
  isEnabled?: boolean
  ignorableStatus?: PaymentJourneyStatus
}) => {
  const { shortCode } = useParams()
  const [start, setStart] = useState(isEnabled)
  const queryClient = useQueryClient()
  const retryInterval = 5 * 1000

  const onErrorHandler = (error: AxiosError) => {
    setStart(false)
    onError(error)
  }

  const onSuccessHandler = async (data: PaymentJourneyStatusResponse) => {
    if (data.status === ignorableStatus) {
      return
    }

    setStart(false)
    await queryClient.invalidateQueries(mfaSessionKey)
    await queryClient.invalidateQueries(fetchOrderKey)

    if (onComplete != null) {
      onComplete(data)
    }
  }

  useEffect(() => {
    setStart(isEnabled)
  }, [isEnabled])

  useQuery<PaymentJourneyStatusResponse, AxiosError>(
    [fetchRedirectUrlsKey, shortCode],
    () => fetchPaymentJourneyStatus(shortCode as string),
    {
      onSuccess: onSuccessHandler,
      onError: onErrorHandler,
      enabled: start,
      refetchInterval: start ? retryInterval : false,
      refetchOnWindowFocus: false,
      retry
    }
  )

  return { startPolling: setStart, isPolling: start }
}
