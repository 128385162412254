import { useQueryClient } from 'react-query'
import { useNavigate, useParams } from 'react-router-dom'
import { PaymentJourneyInfo } from '../types/PaymentJourneyInfo'
import { fetchOrderKey } from './useOrderInfo'

const useFetchCacheData = () => {
  const queryClient = useQueryClient()
  const { shortCode } = useParams()
  const navigate = useNavigate()

  try {
    return queryClient.getQueryData<PaymentJourneyInfo>([fetchOrderKey, shortCode])
  } catch {
    navigate('/error', {
      replace: true
    })
  }
}

export default useFetchCacheData
