import React from 'react'
import OrderErrors, { ErrorMessage } from './OrderErrors'
import useFetchCacheData from '../../custom-hooks/useFetchCacheData'
import { useNavigate } from 'react-router-dom'

const KycCheckIsFailed = () => {
  const paymentJourneyData = useFetchCacheData()
  const navigate = useNavigate()

  if (paymentJourneyData === undefined) {
    navigate('/error', {
      replace: true
    })

    return null
  }

  const errorHeader = `Verification failed`

  return (
    <OrderErrors
      errorHeader={errorHeader}
      marketplaceName={paymentJourneyData.marketplace.name}
      returnToMarketPlaceUrl={paymentJourneyData.paymentJourney.paymentDeclinedRedirectUrl}
    >
      <ErrorMessage>Sorry, but you did not successfully pass the verification check.</ErrorMessage>
      <ErrorMessage>
        Unfortunately, we can't process your payment through Kriya at this time. Please go back to&nbsp;
        {paymentJourneyData.marketplace.name} and select a different payment method to finalise your order.
      </ErrorMessage>
    </OrderErrors>
  )
}

export default KycCheckIsFailed
