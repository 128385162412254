interface IAPIURLMapping {
  [Key: string]: string
}

const apiUrlMapping: IAPIURLMapping = {
  'payments.kriya.co': 'https://api.kriya.co/marketpayapi',
  'payments.kriya.dev': 'https://api.kriya.dev/marketpayapi',
  localhost: 'https://api.kriya.dev/marketpayapi'
}

const apiCompanyInfoUrlMapping: IAPIURLMapping = {
  'payments.kriya.co': 'https://api.kriya.co/company-info',
  'payments.kriya.dev': 'https://api.kriya.dev/company-info',
  localhost: 'https://api.kriya.dev/company-info'
}

const deploymentHost = (currentUrl: string): string => {
  return new URL(currentUrl).hostname
}

const apiBaseUrl = (currentUrl: string): string => {
  return apiUrlMapping[deploymentHost(currentUrl)]
}

const apiCompanyInfoBaseUrl = (currentUrl: string): string => {
  return apiCompanyInfoUrlMapping[deploymentHost(currentUrl)]
}

const retry: number | boolean = deploymentHost(window.location.href) === 'localhost' ? false : 3

export { deploymentHost, retry, apiCompanyInfoBaseUrl }
export default apiBaseUrl
